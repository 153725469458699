import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import ParticipantStatusBar from './participant-status-bar';
import U                    from '../utils/tools';
import VideoUtils           from '../utils/video-tools';
import { apiRequest }       from '../utils/express-api';

class ParticipantMedia extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      audioMuted:     true,
      isPresentation: false,
      videoMuted:     true,
      videoStarted:   false
    };

    this.participantComponentReference = React.createRef();
    U.bindFunctions(this);
  }

  static propTypes = {
    changePresentationTrack:  PropTypes.func,
    currentUserIsAdmin:       PropTypes.bool,
    onEnterPresentation:      PropTypes.func,
    onLeavePresentation:      PropTypes.func,
    onMuteUser:               PropTypes.func,
    participant:              PropTypes.object,
    screenMode:               PropTypes.string,
    user:                     PropTypes.object
  };

  componentDidMount() {
    this.attachParticipantListeners();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.participant !== this.props.participant)
      this.attachParticipantListeners();
  }

  async attachParticipantListeners() {
    var participant = this.props.participant;

    if (!participant)
      return;

    participant.tracks.forEach((publication) => this.trackPublished(publication));

    participant.on('trackPublished', (publication) => this.trackPublished(publication));
    participant.on('trackEnabled', (publication) => this.toggleMedia(publication, true));
    participant.on('trackDisabled', (publication) => this.toggleMedia(publication, false));
    participant.on('trackUnpublished', (publication) => {
      if (U.get(publication, 'track.name') === 'screenshare')
        U.callPropFunction(this.props.onLeavePresentation);
    });
  }

  trackPublished(publication) {
    var container = U.get(this.participantComponentReference, 'current');
    
    if (publication.isSubscribed) {
      VideoUtils.attachTrack(publication.track, container);
      this.toggleMedia(publication, !!publication.isTrackEnabled);
    }

    publication.on('subscribed', (track) => {
      VideoUtils.attachTrack(track, container);
      this.toggleMedia(publication, true);
    });

    publication.on('unsubscribed', async (track) => {
      VideoUtils.detachTrack(track);
      this.toggleMedia(publication, false);
    });
  }

  toggleMedia(publication, isEnabled) {
    if (publication.kind === 'audio')
      return this.setState({ audioMuted: !isEnabled });

    if (publication.kind === 'video') {
      this.setState({ videoMuted: !isEnabled });

      if (publication.trackName === 'screenshare') {
        var {
              onEnterPresentation,
              onLeavePresentation,
              screenMode,
              participant
            }                       = this.props,
            participantSid          = U.get(participant, 'sid'),
            currentlyPresenting     = (screenMode === 'presentation');

        if (isEnabled && !currentlyPresenting)
          U.callPropFunction(onEnterPresentation, [ participantSid ]);

        if (!isEnabled && currentlyPresenting)
          U.callPropFunction(onLeavePresentation);

        this.setState({ isPresentation: isEnabled });
      }
    }
  }

  _changePresentationTrack() {
    var { participant, changePresentationTrack } = this.props,
        sid = U.get(participant, 'sid');

    U.callPropFunction(changePresentationTrack, [ sid ]);
  }

  muteUser() {
    var participantId = U.get(this.props.participant, 'identity');

    apiRequest('post', `participants/${participantId}/mute-user`,{});
  }

  renderNoVideoMessaging() {
    var userName = U.get(this.props.user, 'name');

    if (!userName)
      userName = 'Unknown User';

    userName = ((userName).match(/s$/i)) ? `${userName}'` : `${userName}'s`;

    return (
      <div className="video-hidden">
        {`${userName} camera is off`}
      </div>
    )
  }

  render() {
    var {
          audioMuted,
          isPresentation,
          videoMuted
        }                 = this.state,
        userName          = U.get(this.props.user, 'name'),
        sid               = U.get(this.props.participant, 'sid'),
        presentationMode  = (this.props.screenMode === 'presentation');

    return (
      <div
        id={`participantContainer-${sid}`}
        ref={this.participantComponentReference}
        onClick={(presentationMode) ? this._changePresentationTrack : null}
      >
        {!!videoMuted && this.renderNoVideoMessaging()}
        <ParticipantStatusBar
          audioMuted={audioMuted}
          currentUserIsAdmin={this.props.currentUserIsAdmin}
          isPresentation={isPresentation}
          userName={userName}
          onMuteUser={this.muteUser}
          videoMuted={videoMuted}
        />

      {/* Video/Audio Tracks get added here in TrackPublished */}
      </div>
    );
  }
}

export default ParticipantMedia;
