// Attach array of Tracks to the DOM.
export function attachTracks(tracks, container) {
  tracks.forEach((track) => this.attachTrack(track, container));
}

export function attachTrack(track, container, addVideoStyle = true) {
  // delete other tracks of same type if there
  detachCurrentTracksFromContainer(container, track.kind);

  // add new track
  container.appendChild(track.attach());
  var lastChild = container.lastChild;

  // add portrait or landscape class to video
  if (track.kind === 'video' && addVideoStyle) {
    track.once('started', () => {
      lastChild.classList.add((track.dimensions.width > track.dimensions.height) ? 'landscape' : 'portrait');
    });
  }
}

function detachCurrentTracksFromContainer(container, trackKind) {
  if (!container || !trackKind)
    return;

  var elements = container.getElementsByTagName(trackKind) || [];

  for (var i = 0; i < elements.length; i++) {
    elements[i].remove();
  }
}

// Get the Participant's Tracks.
export function getTracks(participant) {
  return (participant.tracks.values() || []).map((publication) => publication.track).filter(Boolean);
}

// Detach given track from the DOM.
export async function detachTrack(track) {
  track.detach().forEach((element) => element.remove());
}

export function removeLocalParticipantTracks(participant, _trackKind) {
  if (!participant)
    return;

  var trackKind = (_trackKind) ? `${_trackKind}Tracks` : 'tracks';

  participant[trackKind].forEach((publication) => {
    var track = publication.track;

    track.stop();
    participant.unpublishTrack(track);
    detachTrack(track);
  })
}

export default {
  getTracks,
  attachTrack,
  attachTracks,
  detachTrack,
  removeLocalParticipantTracks
}
