import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import U                      from '../../utils/tools';

class LeaveRoomModal extends Component {
  constructor(props, ...args) {
    super(props, ...args);

    U.bindFunctions(this);
  }

  static propTypes = {
    emptyRoom:    PropTypes.bool,
    onFlushRoom:  PropTypes.func,
    onLeaveRoom:  PropTypes.func,
    onClose:      PropTypes.func,
  };

  _onFlushRoom() {
    U.callPropFunction(this.props.onFlushRoom);
  }

  _onLeaveRoom() {
    U.callPropFunction(this.props.onLeaveRoom);
  }

  _onClose() {
    U.callPropFunction(this.props.onClose);
  }

  getEmptyRoomMessaging() {
    return (
      <div>
        <h3>Are you sure you want to end the visit?</h3>
        <div className="inline-buttons">
          <button data-test-id="end-visit-cancel" className="outline" onClick={this._onClose}>
            Cancel
          </button>
          <button data-test-id="end-visit" onClick={this._onFlushRoom}>
            End Visit
          </button>
        </div>
      </div>
    );
  }

  getNonEmptyRoomMessaging() {
    return (
      <div className="leave-room-modal">
        <h2>What would you like to do?</h2>
        <p className="danger">Ending the call will end the visit for everyone.</p>
        <div className="inline-buttons">
          <button data-test-id="end-call" className="outline" onClick={this._onFlushRoom}>
            End Call
          </button>
          <button data-test-id="leave-room" className="outline" onClick={this._onLeaveRoom}>
            Leave Room
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"modal"}>
        <div className="modal-content small">
          <div className="modal-body">
            <div className="close-modal icon-btn" onClick={this._onClose}>
              <i className="icon-close"></i>
            </div>
            {(this.props.emptyRoom) ? this.getEmptyRoomMessaging() : this.getNonEmptyRoomMessaging()}
          </div>
        </div>
      </div>
    );
  }
}

export default LeaveRoomModal;
