import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import U                    from '../../utils/tools';

const DEFAULT_TIMEOUT_DURATION = 60;

class PageTimeoutModal extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      timeRemaining: this.props.timeoutDuration || DEFAULT_TIMEOUT_DURATION
    };

    U.bindFunctions(this);
  }

  static propTypes = {
    timeoutDuration:  PropTypes.number,
    onTimeExpired:    PropTypes.func,
    onCancelTimeout:  PropTypes.func
  };

  componentDidMount() {
    this.startCountdown()
  }

  componentWillUnmount() {
    clearInterval(this.countdownInterval);
    this.countdownInterval = null;
  }

  startCountdown() {
    this.countdownInterval = setInterval(() => {
      (this.state.timeRemaining <= 0)
        ? this._onTimeExpired()
        : this.setState((state) => ({ timeRemaining: state.timeRemaining - 1 }));
    }, 1000);
  }

  _onTimeExpired() {
    clearInterval(this.countdownInterval);
    U.callPropFunction(this.props.onTimeExpired);
  }

  _onCancelTimeout() {
    clearInterval(this.countdownInterval);
    U.callPropFunction(this.props.onCancelTimeout);
  }

  render() {
    return (
      <div className="modal">
        <div className="modal-content small center">
          <div className="modal-body">
            <h1>Are you still here?</h1>
            <p><small>This session will close in {this.state.timeRemaining} seconds.</small></p>
            <button data-test-id="continue-waiting" style={{ margin: 'auto' }} onClick={this._onCancelTimeout}>
              Continue Waiting
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default PageTimeoutModal;
