import React, { Component }   from 'react';

class HelpModal extends Component {
  render() {
    return (
      <div className="modal mobile-modal help-modal">
        <div className="modal-content">
          <div className="modal-body center">
            <h1 className="title center">Help</h1>
            <h6>Our friendly staff is standing by and happy to assist you with whatever you may need.</h6>

            <div className="support-links">
              <button data-test-id="submit-support-ticket" className="support-link" onClick={() => {
                window.open('https://support.evisit.com/?ticket=1', '_system');
              }}>
                <i className="icon-paper-plane"></i>
                <span>Submit Ticket</span>
              </button>

              <div className="vertical-bar"/>

              <button data-test-id="call-support" className="support-link" onClick={() => {
                window.open('tel:19282972294', '_system');
              }}>
                <i className="icon-phone-ringing"></i>
                <span>(928) 297-2294</span>
              </button>
            </div>

            <h6>Like to know more?</h6>

            <button data-test-id="open-knowledge-base" className="join-room large action" onClick={() => {
              window.open('https://support.evisit.com/', '_system');
            }}>
              View Knowledgebase
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default HelpModal;
