import Pusher from "pusher-js";

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER
});

let authorizedPusher;

function getAuthorizedPusher(userId) {
  if (!authorizedPusher) {
    authorizedPusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      authEndpoint: `${process.env.REACT_APP_URL_API}/api/participants/${userId}/pusher-auth`
    });
  }

  return authorizedPusher;
}

class PusherTools {
  constructor() {
    console.log('Cunstructing A new Pusher Instance');
    this.pusherChannels = {
      'adminChannel': null,
      'userInitChannel': null,
      'roomInitChannel': null,
      'userLiveChannel': null,
      'roomLiveChannel': null,
      'userStateChannel': null,
    };
  }

  subscribeToAdminChannel(inviteToken) {
    this.pusherChannels.adminChannel = pusher.subscribe(inviteToken);
  }

  subscribeToInitChannels(sessionName, userIdUnique) {
    this.pusherChannels.roomInitChannel = pusher.subscribe(sessionName);
    this.pusherChannels.userInitChannel = pusher.subscribe(userIdUnique);
  }

  subscribeToLiveChannels(userId, sessionName) {
    const authorizedPusher = getAuthorizedPusher(userId);

    this.pusherChannels.roomLiveChannel = authorizedPusher.subscribe(`presence-${sessionName}`);
    this.pusherChannels.userLiveChannel = authorizedPusher.subscribe(`user-connection-${userId}`);
  }

  subscribeToStateChannels(userId) {
    const authorizedPusher = getAuthorizedPusher(userId);
    this.pusherChannels.userStateChannel = authorizedPusher.subscribe(`user-state-${userId}`);
  }

  unsubscribeToLiveChannels() {
    if (this.pusherChannels.roomLiveChannel) {
      this.pusherChannels.roomLiveChannel.unbind_all();
      this.pusherChannels.roomLiveChannel = null;
    }

    if (this.pusherChannels.userLiveChannel) {
      this.pusherChannels.userLiveChannel.unbind_all();
      this.pusherChannels.userLiveChannel = null;
    }
  }

  unsubscribeToStateChannels() {
    if (!this.pusherChannels.userStateChannel)
      return;
      
    this.pusherChannels.userStateChannel.unbind_all();
    this.pusherChannels.userStateChannel = null;
  }

  unsubscribeToAdminChannels() {
    if (this.pusherChannels.adminChannel) {
      this.pusherChannels.adminChannel.unbind_all();
      this.pusherChannels.adminChannel = null;     
    }
  }

  unsubscribeToAllChannels() {
    const channels = Object.values(this.pusherChannels);

    channels.forEach((channel) => {
      if (!channel)
        return;

      channel.unbind_all();
      channel = null;
    });
  }
}

const pusherTools = new PusherTools();

export default pusherTools;
