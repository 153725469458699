export function isFunction(functionToCheck) {
  return (typeof functionToCheck === 'function');
}

export function guessBooleanFromString(value) {
  return !!('' + value).match(/\W*(enabled|true|yes|yea|yeah|affirmative|y|checked|[1-9]+)/i)
}

export function callPropFunction(propFunction, args, defaultFunction) {
  if (!propFunction && !defaultFunction)
    return;

  return (isFunction(propFunction)) ? propFunction(...(args || [])) : null;
}

export function bindFunctions(source) {
  if (!source)
    return;

  var proto = Object.getPrototypeOf(source),
      names = (proto) ? Object.getOwnPropertyNames(proto) : [];

  for (var i = 0, il = names.length; i < il; i++) {
    var propName = names[i],
        prop = source[propName];

    if (typeof prop !== 'function' || propName === 'constructor' || Object.prototype[propName] === prop)
      continue;

    Object.defineProperty(source, propName, {
      writable: true,
      enumerable: false,
      configurable: true,
      value: prop.bind(source)
    });
  }
}

export function get(object, path, defaultValue) {
  return path.split('.').reduce((output, step) => {
    if (!output)
      return defaultValue || null;

    return output[step];
  }, object);
}

export const EMAIL_REGEX = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const PHONE_REGEX = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/i;

export const INTERNATIONAL_PHONE_REGEX = /^\+(?:[0-9] ?){6,14}[0-9]$/;

export function formatEmailValue(email) {
  var formatEmail = email
    .replace(/[\s\n]/g, '')
    .replace(/[^\x00-\x7F]/g, '')
    .replace(/[`'~!?><"^]/g, '')

  var firstChar = formatEmail.charAt(0),
      lastChar = formatEmail.charAt(formatEmail.length - 1)

  var substring = formatEmail.substring(1),
      substring = substring.substring(-1, substring.length - 1);

  var charToRemove = /[\-_]/g;

  firstChar = firstChar.replace(charToRemove, '');
  lastChar = lastChar.replace(charToRemove, '');

  return firstChar + substring + lastChar;
}

export function formatPhoneValue(phone) {
  return (phone.match(/\+(|\s+)|\d+/g) !== null)
    ? phone.match(/\+(|\s+)|\d+/g).join("") // removes spaces and special character
    : phone;
}

export default {
  bindFunctions,
  callPropFunction,
  get,
  guessBooleanFromString,
  isFunction,
  EMAIL_REGEX,
  PHONE_REGEX,
  INTERNATIONAL_PHONE_REGEX,
  formatEmailValue,
  formatPhoneValue
};
