export const identify = createAnalyticsMethod('identify');
export const page = createAnalyticsMethod('page');
export const track = createAnalyticsMethod('track');

function createAnalyticsMethod(method) {
  return function callAnalytics(...args) {
    if (window.analytics) {
      try {
        window.analytics[method](...args);
      } catch (err) {
        // Avoid segment (a third-party) from halting callee execution.
        console.error(`Call to analytics.${method} failed:`);
        console.error(err);
      }
    } else if (window.aptrinsic) {
      const aptrinsicMethods = { identify, page, track };

      return aptrinsicMethods[method](...args);

      function identify(id, args) {
        window.aptrinsic('identify', {id, ...args});
      }

      function page(pageName, args) {
        window.aptrinsic('pageView', args);
      }

      function track(eventName, args) {
        window.aptrinsic('track', eventName, args);
      }
    }
  }
}

export function addGainsightContext(context) {
  if (!global.aptrinsic) return noop;

  if (context && typeof context !== "object") {
    throw new Error("addGainsightContext only supports object contexts");
  }

  const contextKeys = [];

  for (var key in context) {
    if (Object.hasOwnProperty.call(context, key)) {
      const value = context[key];
      const type = typeof value;
      if (type !== "boolean" && type !== "number" && type !== "string") {
        throw new Error(
          `addGainsightContext: Context key "${key}" is a(n) "${typeof value}", but Gainsight PX Context only supports booleans, numbers, and strings.`
        );
      }

      contextKeys.push(key);
    }
  }

  global.aptrinsic("set", "globalContext", context);

  return function removeGainsightContext() {
    global.aptrinsic("remove", "globalContext", contextKeys);
  };
}

function noop() {}
