import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import VideoUtils             from '../utils/video-tools';
import U                      from '../utils/tools';

class PresentationContainer extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.presentationContainer = React.createRef();
    U.bindFunctions(this);
  }

  static propTypes = {
    presentation:         PropTypes.any,
    onLeavePresentation:  PropTypes.func
  };

  componentDidMount() {
    this.attachPresentation();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    var presentation  = this.props.presentation;

    if (presentation !== prevProps.presentation)
      this.attachPresentation();
  }

  attachPresentation() {
    var presentation  = this.props.presentation,
        container     = U.get(this.presentationContainer, 'current');

    if (!presentation)
      return;

    this.removeCurrentPresentationVideo();
    var presentationTrack = U.get(presentation, 'track');

    VideoUtils.attachTrack(presentationTrack, container, false);
    presentation.on('unsubscribed', this._onLeavePresentation);
  }

  _onLeavePresentation() {
    U.callPropFunction(this.props.onLeavePresentation);
  }

  removeCurrentPresentationVideo() {
    var container         = U.get(this.presentationContainer, 'current'),
        oldVideoElements  = (container) ? container.getElementsByTagName('video') : [];

    if (!oldVideoElements.length)
      return;

    for (var i = 0; i < oldVideoElements.length; i++) {
      oldVideoElements[i].remove();
    }
  }

  render() {
    return (
      <div
        ref={this.presentationContainer}
        id="presentation-media"
        className="presentation-media">
      </div>
    );
  }
}

export default PresentationContainer;
