const STORAGE = window.localStorage;
const STORAGE_PREFIX = 'express-app';

const getSessionVideoID = () => {
  return STORAGE.getItem(`${STORAGE_PREFIX}videoId`);
};

const updateSessionVideoID = (videoId) => {
  if (!videoId) {
    return;
  }

  STORAGE.setItem(`${STORAGE_PREFIX}videoId`, videoId);
};

const deleteSessionVideoID = () => {
  STORAGE.removeItem(`${STORAGE_PREFIX}videoId`);
};

const getSessionAudioID = () => {
  return STORAGE.getItem(`${STORAGE_PREFIX}audioId`);
};

const updateSessionAudioID = (audioId) => {
  if (!audioId) {
    return;
  }

  STORAGE.setItem(`${STORAGE_PREFIX}audioId`, audioId);
};

const deleteSessionAudioID = () => {
  STORAGE.removeItem(`${STORAGE_PREFIX}audioId`);
};

const getSessionBackgroundType = () => {
  return STORAGE.getItem(`${STORAGE_PREFIX}backgroundType`);
};

const updateSessionBackgroundType = (backgroundType) => {
  if (!backgroundType) {
    return;
  }

  STORAGE.setItem(`${STORAGE_PREFIX}backgroundType`, backgroundType);
};

const getSessionBackgroundImage = () => {
  return STORAGE.getItem(`${STORAGE_PREFIX}backgroundImage`);
};

const updateSessionBackgroundImage = (backgroundImage) => {
  if (!backgroundImage) {
    return;
  }

  STORAGE.setItem(`${STORAGE_PREFIX}backgroundImage`, backgroundImage);
};

export {
  getSessionVideoID,
  updateSessionVideoID,
  deleteSessionVideoID,
  getSessionAudioID,
  updateSessionAudioID,
  deleteSessionAudioID,
  getSessionBackgroundType,
  updateSessionBackgroundType,
  getSessionBackgroundImage,
  updateSessionBackgroundImage
};
