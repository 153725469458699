import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import U                    from '../../utils/tools';
import { apiRequest }       from "../../utils/express-api";

class VisitEndedModal extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      durationTimes: this.props.durationTimes
    };
  }

  static propTypes = {
    date:               PropTypes.string,
    durationMinutes:    PropTypes.string,
    endTime:            PropTypes.string,
    footer:             PropTypes.string,
    header:             PropTypes.string,
    durationTimes:      PropTypes.object,
    inviteToken:        PropTypes.string,
    renderModalSpinner: PropTypes.func,
    startTime:          PropTypes.string,
  };

  getDurationVerbiage(secondCount) {
    var durationMinutes = Math.floor(secondCount / 60),
        durationSeconds = secondCount % 60,
        minuteVerbiage  = (durationMinutes === 1) ? 'minute' : 'minutes',
        secondVerbiage  = (durationSeconds === 1) ? 'second' : 'seconds';

    return `${durationMinutes} ${minuteVerbiage} and ${durationSeconds} ${secondVerbiage}`;
  }

  getDurationTimes() {
    apiRequest('get', `participants/${this.props.inviteToken}`).then((response) => {
      var durationTimes = U.get(response, 'durationTimes', {});

      if (Object.keys(durationTimes).length > 0)
        this.setState({ durationTimes });
    });
  }

  renderDurationTimes() {
    var durationTimes = this.state.durationTimes;

    if (Object.keys(durationTimes).length === 0) {
      setTimeout(() => this.getDurationTimes(), 700);

      return this.props.renderModalSpinner();
    }


    return Object.keys(durationTimes).map((key, index) => {
      return (
        <div className='participant-duration' key={`duration-time-${index}`}>
          {(index > 0) && <hr />}
          <p>{key}</p>
          <p>{this.getDurationVerbiage(durationTimes[key])}</p>
        </div>
      );
    });
  }

  render() {
    var { header, date, durationSeconds, footer } = this.props;

    return (
      <div>
        <div className="ended-modal">
          <div className="ended-content">
            <h1>{header}</h1>
            <p>Date: {date}</p>
            <p>Duration: {this.getDurationVerbiage(durationSeconds)}</p>
            <div className='participant-duration-title'>Participant Durations</div>
            {this.renderDurationTimes()}
          </div>
        </div>
        <div className="ended-footer">
          <p>{footer}</p>
        </div>
      </div>
    );
  }
}

export default VisitEndedModal;
