import React, { Component }                           from 'react';
import PropTypes                                      from 'prop-types';
import U                                              from '../utils/tools';
import {
  updateSessionVideoID,
  updateSessionAudioID
}                                                     from '../utils/storage';
import {
  BACKGROUND_TYPE_BLUR,
  BACKGROUND_TYPE_NONE,
  BACKGROUND_TYPE_IMAGE,
  BACKGROUND_IMAGE_NONE,
}                                                     from '../utils/background-tools';
import ImageSelector from './common-components/image-selector';

class SettingsPanel extends Component {
  constructor(props, ...args) {
    super(props, ...args);

    this.state = {
      videoDevices:             props.videoDevices || [],
      audioDevices:             props.audioDevices || [],
      videoDeviceId:            props.videoDeviceId,
      audioDeviceId:            props.audioDeviceId,
      backgroundType:           props.backgroundType,
      backgroundImageSelected:  props.backgroundImageSelected,
      settingAudioAndVideo:     true,
    };

    U.bindFunctions(this);
  }

  static propTypes = {
    audioDevices:             PropTypes.array,
    videoDevices:             PropTypes.array,
    audioDeviceId:            PropTypes.number,
    videoDeviceId:            PropTypes.number,
    backgroundType:           PropTypes.string,
    backgroundImageNames:     PropTypes.array,
    backgroundImageSelected:  PropTypes.string,
    isAdmin:                  PropTypes.bool,
    onClose:                  PropTypes.func.isRequired,
    onToggleAudioDevice:      PropTypes.func.isRequired,
    onToggleVideoDevice:      PropTypes.func.isRequired,
    onToggleBackground:       PropTypes.func.isRequired,
  };

  onVideoDeviceChange(event) {
    if (!event || !event.target || !event.target.value)
      return;

    var videoDeviceId = parseInt(event.target.value, 10);
    this.setState({ videoDeviceId });
  }

  onAudioDeviceChange(event) {
    if (!event || !event.target || !event.target.value)
      return;

    var audioDeviceId = parseInt(event.target.value, 10);
    this.setState({ audioDeviceId })
  }

  onBackgroundBlurSelected(optionSelected) {
    const backgroundType = (optionSelected === BACKGROUND_TYPE_NONE) ? BACKGROUND_TYPE_NONE : BACKGROUND_TYPE_BLUR;
    this.setState({ backgroundType, backgroundImageSelected: BACKGROUND_IMAGE_NONE });
  }

  onBackgroundImagesChange(imageName) {
    const backgroundType = (imageName === BACKGROUND_TYPE_NONE) ? BACKGROUND_TYPE_NONE : BACKGROUND_TYPE_IMAGE;
    this.setState({ backgroundType, backgroundImageSelected: imageName });
  }

  updateAndClose() {
    var { videoDeviceId, audioDeviceId, videoDevices, audioDevices, backgroundType, backgroundImageSelected } = this.state;

    var videoDevice = videoDevices[videoDeviceId];
    var audioDevice = audioDevices[audioDeviceId];

    updateSessionVideoID(videoDevice.id);
    updateSessionAudioID(audioDevice.id);

    U.callPropFunction(this.props.onToggleVideoDevice, [ videoDeviceId ]);
    U.callPropFunction(this.props.onToggleAudioDevice, [ audioDeviceId ]);
    U.callPropFunction(this.props.onToggleBackground, [ backgroundType, backgroundImageSelected ]);
    U.callPropFunction(this.props.onClose);
  }

  getAudioDeviceOptions() {
    return this.state.audioDevices.map((item, index) => {
      return <option value={index} key={index}>{item.label}</option>
    });
  }

  getVideoDeviceOptions() {
    return this.state.videoDevices.map((item, index) => {
      return <option value={index} key={index}>{item.label}</option>
    });
  }

  render() {
    var {
      videoDeviceId,
      audioDeviceId,
      videoDevices,
      audioDevices,
      backgroundType,
      settingAudioAndVideo,
    } = this.state;

    const showBackgroundImage = this.props.backgroundImageNames.length > 0;

    return (
      <div className={"modal settings-panel"}>
        <div className="modal-content small">
          <div className="modal-body small-min-height">
            <div
              className="close-modal icon-btn"
              onClick={() => this.props.onClose()}
            >
              <i className="icon-close" />
            </div>
            <h1 className="modal-content center">Settings</h1>

            <div className="input-group modal-tab">
              <div
                className={
                  settingAudioAndVideo ? 
                  "modal-icon-label modal-tab-option-active" :
                  "modal-icon-label modal-tab-option-inactive"
                }
                onClick={() => {
                  this.setState({ settingAudioAndVideo: true });
                }}
              >
                <i className="icon-cog" />
                <label>Audio & Video</label>
              </div>
              <div
                className={
                  !settingAudioAndVideo ? 
                  "modal-icon-label modal-tab-option-active" :
                  "modal-icon-label modal-tab-option-inactive"
                }
                onClick={() => {
                  this.setState({ settingAudioAndVideo: false });
                }}
              >
                <i className="icon-sparkles" />
                <label>Background</label>
              </div>
            </div>

            {settingAudioAndVideo && (
              <div>
                <div className="input-group">
                  <div className="modal-icon-label">
                    <i className="icon-video" />
                    <label>Camera</label>
                  </div>

                  <div className="select">
                    <select
                      id="video-device-list"
                      className="small outline"
                      value={videoDeviceId}
                      disabled={videoDevices.length < 2}
                      onChange={this.onVideoDeviceChange}
                    >
                      {this.getVideoDeviceOptions()}
                    </select>
                  </div>
                </div>

                <div className="input-group">
                  <div className="modal-icon-label">
                    <i className="icon-mic" />
                    <label>Microphone</label>
                  </div>

                  <div className="select">
                    <select
                      id="audio-device-list"
                      className="small outline"
                      value={audioDeviceId}
                      disabled={audioDevices.length < 2}
                      onChange={this.onAudioDeviceChange}
                    >
                      {this.getAudioDeviceOptions()}
                    </select>
                  </div>
                </div>
              </div>
            )}
            {!settingAudioAndVideo && (
              <div>
                <div className="input-group">
                    <div className="modal-icon-label">
                      <label>Background Blur</label>
                    </div>
                    <div>
                    <ImageSelector
                      imagesPath={this.props.pathImages}
                      imagesNames={[BACKGROUND_TYPE_BLUR]}
                      imageSelected={backgroundType === BACKGROUND_TYPE_BLUR ? BACKGROUND_TYPE_BLUR : BACKGROUND_TYPE_NONE}
                      onImageSelected={this.onBackgroundBlurSelected}
                      caption={true}
                    />
                  </div>
                  </div>
                  
                {(this.props.isAdmin && showBackgroundImage) && (
                  <div className="input-group">
                    <div className="modal-icon-label">
                      <label>Background Image</label>
                    </div>
                    <ImageSelector
                      imagesPath={this.props.pathImages}
                      imagesNames={this.props.backgroundImageNames}
                      imageSelected={this.state.backgroundImageSelected}
                      onImageSelected={this.onBackgroundImagesChange}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="button-container">
              <button
                data-test-id="update-device-settings"
                className=""
                onClick={this.updateAndClose}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default SettingsPanel;
