import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import generateClassNames     from 'classnames';
import Twilio                 from 'twilio-video';
import * as VideoProcessors   from '@twilio/video-processors';
import { isMobile }           from 'react-device-detect';
import FeatureInvitePanel     from './feature-invite-panel';
import SettingsPanel          from './settings-panel';
import VideoControls          from './video-controls';
import LocalMedia             from './local-media';
import PresentationContainer  from './presentation-container';
import ParticipantMedia       from './participant-media';
import PageTimeoutModal       from './modals/page-timeout-modal';
import LeaveRoomModal         from './modals/leave-room-modal';
import VideoUtils             from '../utils/video-tools';
import pusherTools            from '../utils/pusher';
import { apiRequest }         from '../utils/express-api';
import U                      from '../utils/tools';
import { 
  getSessionBackgroundType,
  updateSessionBackgroundType,
  getSessionBackgroundImage,
  updateSessionBackgroundImage
}                             from '../utils/storage';
import {
  BACKGROUND_TYPE_BLUR,
  BACKGROUND_BLUR_SETTINGS,
  BACKGROUND_TYPE_NONE,
  BACKGROUND_TYPE_IMAGE,
  BACKGROUND_IMAGE_NONE,
  BACKGROUND_BLUR_NOT_SUPPORTED,
  BACKGROUND_IMAGES_NOT_SUPPORTED,
  VIRTUAL_BACKGROUND_ASSETS
}                             from '../utils/background-tools';
import { 
  LEAVE_VISIT_EVENT,
  VISIT_END_EVENT,
  AUDIO_RECEIVED_EVENT,
  VIDEO_RECEIVED_EVENT
}                             from '../utils/analytics-events';

const MOUSE_TIMER_DURATION      = 3000,
      TALKING_TIMER_DURATION    = 2000,
      VISIBILITY_CHANGE_TIMEOUT = 1000,
      PAGE_TIMEOUT_DURATION     = 60 * 60000; // Warning will display after 1 hour (60 * 60,000ms = 60 * 1min = 1hr)

const VIDEO_EVENT_TYPE_OFF = 'off',
      VIDEO_EVENT_TYPE_ON  = 'on';

const BREAK_LARGE_WIDTH = 1024;

class VideoChatPage extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      backgroundType:       null,
      blurProcessor:        null,
      controlsVisible:      true,
      deniedUsers:          [],
      facingMode:           'user',
      imageProcessor:       null,
      invitePanel:          false,
      isDocumentVisible:    true,
      isTalking:            false,
      leaveRoomModal:       false,
      lowBandwidthInit:     false,
      networkLowestLevel:   99,
      networkQualityLevel:  null,
      networkQualityLevels: [],
      presentation:         null,
      remoteCount:          0,
      roomLive:             this.props.roomLive,
      screenMode:           'default', // default and presentation are only values
      screenShareId:        null,
      sentInvites:          [],
      settingsPanel:        false,
      sharingScreen:        false,
      startTime:            null,
      stopTime:             null,
      users:                [],

      //////////////
      // Temp added for getting audioLevel since Twilio's stats are currently returning null
      // https://github.com/twilio/twilio-video.js/issues/1140
      //////////////
      analyser:             null,
      audioContext:         null,
      muteWarning:          false,
      scriptProcessor:      null,
    };

    U.bindFunctions(this);
  }

  static propTypes = {
    activeRoom:           PropTypes.object,
    addNotification:      PropTypes.func,
    appId:                PropTypes.string,
    audioDeviceId:        PropTypes.number,
    audioDevices:         PropTypes.array,
    audioMuted:           PropTypes.bool,
    autoJoin:             PropTypes.bool,
    backgroundImagesMap:  PropTypes.object,
    displayName:          PropTypes.string,
    hostEnded:            PropTypes.bool,
    inviteToken:          PropTypes.string,
    isAdmin:              PropTypes.bool,
    onRoomDisconnected:   PropTypes.func,
    pathImages:           PropTypes.string,
    practiceId:           PropTypes.number,
    roomInviteUrl:        PropTypes.string,
    roomLive:             PropTypes.bool,
    sessionName:          PropTypes.string,
    sharingScreen:        PropTypes.bool,
    updateSessionState:   PropTypes.func,
    userId:               PropTypes.number,
    userIdUnique:         PropTypes.string,
    videoDeviceId:        PropTypes.number,
    videoDevices:         PropTypes.array,
    videoMuted:           PropTypes.bool,
    videoRoomId:          PropTypes.number,
    worker:               PropTypes.func,
  };

  leaveRoomOnRefresh = (event) => {
    if (!this.props.activeRoom)
      return;

    this.leaveRoom();

    event.preventDefault();
    event.returnValue = '';
  }
  componentDidMount() {
    // handles browser close during visit
    window.addEventListener('beforeunload', this.leaveRoomOnRefresh);

    this.pusherLiveChannels();
    this.roomJoined();
    this.toggleBackground(this.state.backgroundType, BACKGROUND_IMAGE_NONE);
    this.monitorSwitchedOffs();

    // for control panel show/hide
    if (!isMobile)
      window.addEventListener('mousemove', this.resetMouseTimer);

    document.addEventListener('visibilitychange', this.updateIsDocumentVisible);
  }

  componentWillUnmount() {
    if (isMobile)
      document.removeEventListener('visibilitychange', this.updateVideoVisibility);

    if (!isMobile)
      window.removeEventListener('mousemove', this.resetMouseTimer);

    window.removeEventListener('beforeunload', this.leaveRoomOnRefresh);
    document.removeEventListener('visibilitychange', this.updateIsDocumentVisible);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.activeRoom)
      return;

    this.handlePageTimeout();
    this.updateLocalNetworkStats();
  }

  handlePageTimeout() {
    var remoteCount   = this.state.remoteCount,
        pageTimeoutId = this.pageTimeoutId,
        startTimeout  = (remoteCount < 1 && !pageTimeoutId),
        stopTimeout   = (remoteCount > 0 && pageTimeoutId);

    if (startTimeout)
      this.pageTimeoutId = setTimeout(() => this.setState({ timeoutPanel: true }), PAGE_TIMEOUT_DURATION);

    if (stopTimeout)
      this.cancelPageTimeout();
  }

  cancelPageTimeout() {
    this.pageTimeoutId = null;
    this.setState({ timeoutPanel: false });
  }

  // grabs network quality scores on interval
  startNetworkMonitoring() {
    this.monitorInterval = setInterval(this.monitorNetwork, 3000);
  }

  // watching network levels from twilio ( 0=disconnected, 1-5 alive)
  updateLocalNetworkStats() {
    var addNotification     = this.props.addNotification,
        networkQualityLevel = U.get(this.props.activeRoom, 'localParticipant.networkQualityLevel'),
        lowBandwidthInit    = this.state.lowBandwidthInit;

    if (!networkQualityLevel || (networkQualityLevel === this.state.networkQualityLevel))
      return;

    this.setState({ networkQualityLevel });

    if (networkQualityLevel < this.state.networkLowestLevel)
      this.setState({ networkLowestLevel: networkQualityLevel });

    if (networkQualityLevel < 2) {
       if (lowBandwidthInit) {
         U.callPropFunction(addNotification, ['Your network connection has slowed.', 'warn', 5000])
       } else {
         this.setState({ lowBandwidthInit: true });
       }
    }

    if (networkQualityLevel < 1) {
      U.callPropFunction(addNotification, ['Connection lost.', 'negative', 5000]);
      this.leaveRoom();
    }
  }

  updateParticipantNetworkStats() {
    var data                  = {},
        networkQualityLevels  = this.state.networkQualityLevels,
        qualityLevelsLength   = networkQualityLevels.length;

    if (qualityLevelsLength < 1)
      return;

    var total = networkQualityLevels.reduce((total, nextValue) => total + nextValue),
        avg   = (total / qualityLevelsLength).toFixed(2);

    data.lowestBandwidth  = this.state.networkLowestLevel;
    data.averageBandwidth = avg * 1;

    apiRequest('post', `participants/${this.props.userId}/network-stats`, data);
  }

  updateIsDocumentVisible() {
    if (!this.state.isDocumentVisible) {
      setTimeout(() => this.setState({ isDocumentVisible: true }), VISIBILITY_CHANGE_TIMEOUT);
    } else {
      this.setState({ isDocumentVisible: false });
    }
  }

  monitorNetwork() {
    var networkQualityLevel = U.get(this.props.activeRoom, 'localParticipant.networkQualityLevel');

    if (!networkQualityLevel)
      return;

    this.setState({ networkQualityLevels: [ ...this.state.networkQualityLevels, networkQualityLevel ] });
  }

  monitorSwitchedOffs() {
    let { activeRoom } = this.props;
    activeRoom.participants.forEach((remoteParticipant) => {
      let videoTracks = U.get(remoteParticipant, 'videoTracks');
      videoTracks.forEach((remoteVideoTrackPublication) => {
        let remoteVideoTrack = U.get(remoteVideoTrackPublication, 'track');
        this.handleSwitchedOff(remoteParticipant, remoteVideoTrack);
      });
      // Handle when remote participant changes the camera
      remoteParticipant.on('trackSubscribed', (remoteTrack) =>  {
        this.handleSwitchedOff(remoteParticipant, remoteTrack);
        this.remoteTracksAnalytics(remoteParticipant, remoteTrack)
      });
    });
  }

  stopNetworkMonitoring() {
    clearInterval(this.monitorInterval);
  }

  async pusherLiveChannels() {
    await pusherTools.subscribeToLiveChannels(this.props.userId, this.props.sessionName);

    pusherTools.pusherChannels.roomLiveChannel.bind('refresh-participants', () => this.updateUsers());

    pusherTools.pusherChannels.roomLiveChannel.bind('host-changed', (data) => {
      let { isAdmin, userId } = data;
      
      let users = this.state.users;

      users.forEach(element => {
        if (element.id == userId)
          element.isAdmin = isAdmin;
      });

      this.setState({
        users
      });

    });

    pusherTools.pusherChannels.userLiveChannel.bind('mute-user', () => {
      if (!this.props.audioMuted)
        this.toggleMuteAudio();
    });

    pusherTools.pusherChannels.userLiveChannel.bind('leave-room', () => {
      this.leaveRoomAndTrackEvents();
    });
  }

  async videoShutOff(participantId, eventType) {
    const { 
      screenMode,
      isDocumentVisible } = this.state;
    let trackVideoShutOff = screenMode === 'presentation' ?
      isDocumentVisible && window.innerWidth > BREAK_LARGE_WIDTH :
      isDocumentVisible;
    if (trackVideoShutOff) {
      if (eventType === VIDEO_EVENT_TYPE_OFF) {
        let participant     = this.state.users.find((user) => (user.id === participantId)),
            addNotification = this.props.addNotification;
        U.callPropFunction(addNotification,
          [`Your network connection is poor. We turned off ${participant.name}'s video to improve audio quality.`, 'warn', 5000]);
      }
      const eventData = {
        videoRoomId: this.props.videoRoomId,
        participantId: participantId,
        affectedParticipantId: this.props.userId,
        eventType: eventType,
      }
      let response = await apiRequest('post', `video-shutoff-events/${this.props.videoRoomId}`, eventData);
      return response;
    }
  }

  handleSwitchedOff(remoteParticipant, remoteTrack) {
    let participantId   = parseInt(remoteParticipant.identity),
        remoteTrackKind = U.get(remoteTrack, 'kind');

    if (remoteTrack && remoteTrackKind === 'video') {
      remoteTrack.on('switchedOff', () => {
        this.videoShutOff(participantId, VIDEO_EVENT_TYPE_OFF);
      });
      remoteTrack.on('switchedOn', () => {
        this.videoShutOff(participantId, VIDEO_EVENT_TYPE_ON);
      });
    }
  }

  // success on join room, now...
  async roomJoined() {
    var activeRoom        = this.props.activeRoom,
        localParticipant  = U.get(activeRoom, 'localParticipant'),
        twilioId          = U.get(localParticipant, 'sid');

    this.startNetworkMonitoring();

    this.props.activeRoom.participants.forEach((participant) => {      
      this.checkTracks(participant);
    });

    activeRoom.on('participantConnected', (participant) => {      
      this.participantConnected(participant, true);
      this.updateUsers();

      participant.on('trackSubscribed', (remoteTrack) =>  {
        this.handleSwitchedOff(participant, remoteTrack);
        this.remoteTracksAnalytics(participant, remoteTrack)
      });
    });

    activeRoom.on('participantDisconnected', (participant) => {
      this.removeUser(participant)
      this.updateUsers();
    });

    activeRoom.on('disconnected', this._onRoomDisconnected);
    activeRoom.participants.forEach((participant) => this.participantConnected(participant, false));

    U.callPropFunction(this.props.updateSessionState, [{ twilioId }]);

    this.setState({ startTime: new Date().toJSON() });
    window.sessionStorage.setItem('express-connected', true);

    if (this.props.isAdmin && !this.state.remoteCount && !this.props.autoJoin)
      this.toggleInvite();
  }

  remoteTracksAnalytics(participant, remoteTrack) {  
    let trackReceived = {
      userId : this.props.userId,
      receivedTrackUserId : participant.identity, 
      receivedTrackType : remoteTrack.kind, 
      roomId : this.props.videoRoomId
    }
    
    U.callPropFunction(this.props.trackEvent, 
      [remoteTrack.kind === "audio" ? AUDIO_RECEIVED_EVENT : VIDEO_RECEIVED_EVENT , trackReceived]);
  }

  checkTracks(participant) {
    const audioTracks = U.get(participant, 'audioTracks');
    const videoTracks = U.get(participant, 'videoTracks');
      
    if (audioTracks){
      U.callPropFunction(this.props.trackEvent, [AUDIO_RECEIVED_EVENT, {
        userId : this.props.userId,
        receivedTrackUserId : participant.identity, 
        receivedTrackType : 'audio', 
        roomId : this.props.videoRoomId
      }]);
    }

    if (videoTracks){
      U.callPropFunction(this.props.trackEvent, [VIDEO_RECEIVED_EVENT, {
        userId : this.props.userId,
        receivedTrackUserId : participant.identity, 
        receivedTrackType : 'video', 
        roomId : this.props.videoRoomId
      }]);
    }
  }

  // fired for everyone through pusher when invites are added, and on init
  async updateUsers() {
    const isAdmin = this.props.isAdmin;
    try {
      var response    = await apiRequest('post', `video-rooms/${this.props.videoRoomId}/snapshot`, { isAdmin }),
          sentInvites = U.get(response, 'sentInvites', []),
          deniedUsers = U.get(response, 'deniedUsers', []);

      this.setState({
        sentInvites,
        deniedUsers,
      });

    } catch(error) {
      console.log(error);
    }
  }

  startTalkingTimer() {
    // Always stop the silence timer to prevent it stopping the talkingTimer
    if (this.silenceTimerId) {
      window.clearTimeout(this.silenceTimerId);
      this.silenceTimerId = null;
    }

    if (!this.talkingTimerId) {
      this.talkingTimerId = setTimeout(() => {
        this.setState({ isTalking: true });
        this.checkMuteWarning();
        this.talkingTimerId = null;
      }, TALKING_TIMER_DURATION);
    }
  };

  startSilenceTimer() {
    if (this.silenceTimerId)
      return;

    this.silenceTimerId = setTimeout(() => {
      // Only stop the talking timer if there has been silence long enough for the timeout to run
      if (this.talkingTimerId) {
        window.clearTimeout(this.talkingTimerId);
        this.talkingTimerId = null;
      }

      this.setState({ isTalking: false });
      this.checkMuteWarning();
      this.silenceTimerId = null;
    }, TALKING_TIMER_DURATION / 2);
  };

  attachAudioLevelListener(audioElement) {
    if (!audioElement)
      return;

    var { audioContext, analyser, scriptProcessor } = this.state,
        mediaStream                                 = audioElement.srcObject.clone(),
        microphone,
        that                                        = this;

    if (!audioContext) {
      var globalAudioContext = window.AudioContext || window.webkitAudioContext || null;
      audioContext = (globalAudioContext) ? new globalAudioContext() : null;

      if (!audioContext)
        return;
    }

    if (!analyser)
      analyser = audioContext.createAnalyser();

    if (!scriptProcessor)
      scriptProcessor = audioContext.createScriptProcessor(4096, 1, 1);

    microphone = audioContext.createMediaStreamSource(mediaStream);
    microphone.connect(analyser);
    analyser.connect(scriptProcessor);
    scriptProcessor.connect(audioContext.destination);

    scriptProcessor.onaudioprocess = async function() {
      var array     = new Uint8Array(analyser.frequencyBinCount),
          length    = array.length,
          isTalking = that.state.isTalking;

      analyser.getByteFrequencyData(array);

      if (length < 1)
        return;

      var sum             = array.reduce((total, nextValue) => total + nextValue),
          average          = sum / length,
          currentlyTalking = (average > 15),
          endingSilence    = (!isTalking || that.silenceTimerId),
          startingSilence  = (isTalking || that.talkingTimerId);

      if (currentlyTalking && endingSilence)
        that.startTalkingTimer();
      else if (!currentlyTalking && startingSilence)
        that.startSilenceTimer();
    };

    this.setState({ audioContext, analyser, scriptProcessor, microphone });
  }

  checkMuteWarning() {
    if (!this.props.audioMuted || !this.state.isTalking)
      return this.setState({ muteWarning: false });

    this.setState({ muteWarning: true });

    if (this.clearMuteWarningTimoutId)
      return;

    this.clearMuteWarningTimoutId = setTimeout(() => {
      this.setState({ muteWarning: false });
      this.clearMuteWarningTimoutId = null;
    }, 5000);
  }

  flushRoom() {
    apiRequest('post', `video-rooms/${this.props.videoRoomId}/room-flush`, {
      sessionName: this.props.sessionName
    });

    this.leaveRoom(true);
  }

  // only for admin/host, to prompt modal for 'leave' or 'end' call
  leaveRoomInit() {
    if (this.props.isAdmin || this.state.remoteCount < 1 || this.appId)
      return this.setState({ leaveRoomModal: true });

    this.leaveRoomAndTrackEvents();
  }

  // leave twilio video room
  async leaveRoom(flush = false) {
    this.props.worker.disconnect(this.props.userId);

    var roomLive = (!(flush || this.props.hostEnded || this.state.remoteCount < 1));

    await this.setState({ roomLive });

    if (flush) {
      this.setState({ users: [] });
      U.callPropFunction(this.props.updateSessionState, [{ visitEnded: true }]);
    }

    apiRequest('post', 'video-rooms/room-leave', {
      id: this.props.userId,
    });

    this.stopNetworkMonitoring();
    this.updateParticipantNetworkStats();

    await pusherTools.unsubscribeToLiveChannels();
    this.props.activeRoom.disconnect();
  }

  async leaveRoomAndTrackEvents(flush = false) {
    await this.leaveRoom(flush);
    const { isAdmin, videoRoomId } = this.props,
          { roomLive }             = this.state;

    if (!roomLive) {
      U.callPropFunction(this.props.trackEvent, [VISIT_END_EVENT, { isAdmin, videoRoomId }]);
    } else {
      U.callPropFunction(this.props.trackEvent, [LEAVE_VISIT_EVENT, { isAdmin, videoRoomId }]);
    }
  }

  _onRoomDisconnected() {
    U.callPropFunction(this.props.onRoomDisconnected, [ this.state.roomLive ]);
  }

  // mousetimers to hide control panel
  startMouseTimer() {
    this.mouseTimerId = setTimeout(() => {
      this.setState({ controlsVisible: false })
    }, MOUSE_TIMER_DURATION);
  };

  resetMouseTimer() {
    window.clearTimeout(this.mouseTimerId);
    this.startMouseTimer();
    this.setState({ controlsVisible: true });
  };

  // shows/hides invite panel
  toggleInvite() {
    var invitePanel = !this.state.invitePanel;
    this.setState({ invitePanel });
  }

  // toggle video off/on
  async toggleMuteVideo() {
    var videoMuted = !this.props.videoMuted;
    U.callPropFunction(this.props.updateSessionState, [{ videoMuted }]);

    if (this.state.sharingScreen)
      return;

    var activeRoom        = this.props.activeRoom,
        localParticipant  = U.get(activeRoom, 'localParticipant');

    if (!videoMuted) {
      try {
        var { videoDevices, videoDeviceId } = this.props,
            localVideoTrack                 = await Twilio.createLocalVideoTrack({
              deviceId: (videoDeviceId != null && videoDevices.length) ? { exact: videoDevices[videoDeviceId].id } : null,
            });

        await localParticipant.publishTrack(localVideoTrack);
        await this.toggleBackground(this.state.backgroundType, BACKGROUND_IMAGE_NONE);
      } catch (error) {
        console.log(error);
      }
    } else {
      VideoUtils.removeLocalParticipantTracks(localParticipant, 'video');
    }
  }

  async toggleMuteAudio() {
    var audioMuted = !this.props.audioMuted;
    U.callPropFunction(this.props.updateSessionState, [{ audioMuted }]);

    if (!audioMuted) {
      this.checkMuteWarning();
    } else {
      this.talkingTimerId = null;
      this.setState({ isTalking: false });
    }

    var localParticipant  = U.get(this.props.activeRoom, 'localParticipant'),
        localMediaElement = document.getElementById('local-media'),
        localAudioElement = (localMediaElement) ? localMediaElement.getElementsByTagName('audio') : [];

    localParticipant.audioTracks.forEach((publication) => {
      if (audioMuted) {
        publication.track.disable();
      } else {
        (localAudioElement.length) ? publication.track.enable() : this.toggleAudioDevice(this.props.audioDeviceId);
      }
    });
  }

  async initializeBackgroundProcessors() {
    const { backgroundImagesMap } = this.props;
    if (!this.state.blurProcessor) {
      let blurProcessor = new VideoProcessors.GaussianBlurBackgroundProcessor(
        BACKGROUND_BLUR_SETTINGS
      );
      await blurProcessor.loadModel();
      this.setState({ blurProcessor });
    }

    if (!this.state.imageProcessor && backgroundImagesMap.size > 0) {
      const [firstImage] = backgroundImagesMap.values();
      const imageProcessor = new VideoProcessors.VirtualBackgroundProcessor({
        assetsPath: VIRTUAL_BACKGROUND_ASSETS,
        backgroundImage: firstImage,
        maskBlurRadius: 5,
      });
      await imageProcessor.loadModel();
      this.setState({ imageProcessor });
    }
  }


  removeVideoProcessor(videoTrack, backgroundType) {
    let
      processor = U.get(videoTrack, 'processor');

    if (processor && 
      (backgroundType === this.state.backgroundType || backgroundType === BACKGROUND_TYPE_NONE)) {
      videoTrack.removeProcessor(processor);
    }
  }

  getLocalVideoTrack() {
    let
    participant      = U.get(this.props.activeRoom, 'localParticipant'),
    localVideoTracks = U.get(participant, 'videoTracks'),
    videoTrack;

    localVideoTracks.forEach((trackPublication) => { 
      if (!trackPublication.trackName.includes('screen') && trackPublication.kind === 'video') {
        videoTrack = trackPublication.track;
      }
    });
    return videoTrack;
  }

  async toggleBackground(backgroundType, backgroundImageSelected) {
    if (!VideoProcessors.isSupported) {
      if (backgroundType === BACKGROUND_TYPE_BLUR)
        this.props.addNotification(BACKGROUND_BLUR_NOT_SUPPORTED, 'negative');
      if (backgroundType === BACKGROUND_TYPE_IMAGE)
        this.props.addNotification(BACKGROUND_IMAGES_NOT_SUPPORTED, 'negative');
      return;
    }

    if (!backgroundType) {
      backgroundType = getSessionBackgroundType() || BACKGROUND_TYPE_NONE;
    }

    if (!backgroundImageSelected || 
      (backgroundType === BACKGROUND_TYPE_IMAGE && 
      backgroundImageSelected === BACKGROUND_IMAGE_NONE)) {
      backgroundImageSelected = getSessionBackgroundImage() || backgroundImageSelected;
    }

    this.setState({ backgroundType, backgroundImageSelected });
    await this.initializeBackgroundProcessors();

    let imageProcessor = this.state.imageProcessor,
        videoTrack = this.getLocalVideoTrack();

    if (videoTrack && backgroundType === BACKGROUND_TYPE_BLUR) {
      this.removeVideoProcessor(videoTrack, backgroundType);
      videoTrack.addProcessor(this.state.blurProcessor);
    } else if (videoTrack && backgroundType === BACKGROUND_TYPE_IMAGE && this.props.backgroundImagesMap.get(backgroundImageSelected)) {
      imageProcessor.backgroundImage = this.props.backgroundImagesMap.get(backgroundImageSelected);
      this.removeVideoProcessor(videoTrack, backgroundType);
      videoTrack.addProcessor(imageProcessor);
      this.setState({ imageProcessor });
    } else {
      this.removeVideoProcessor(videoTrack, backgroundType);
    }

    updateSessionBackgroundType(backgroundType);
    updateSessionBackgroundImage(backgroundImageSelected);
  }

  async flipMobileCamera() {
    var currentDirection = this.state.facingMode,
        newDirection     = (currentDirection === 'user') ? 'environment' : 'user',
        localParticipant = U.get(this.props.activeRoom, 'localParticipant');


    if (!localParticipant)
      return;

    try {
      var localVideoTrack = null;

      localParticipant.tracks.forEach((track) => {
        if (track.kind === 'video') {
          localVideoTrack = track;
        }
      });

      if (!localVideoTrack)
        return;

      localVideoTrack.track.restart({ facingMode: newDirection });
      this.setState({ facingMode: newDirection });

      if (this.props.videoMuted)
        localVideoTrack.disable();
    } catch(error) {
      console.log(error);
    }
  }

  async getScreenShare() {
    if (!U.isFunction(navigator.mediaDevices.getDisplayMedia))
      return alert('No Screen Share Support');

    try {
      var stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      this.toggleScreenShare(stream);
    } catch (error) {
      console.log(error);
    }
  }

  async toggleScreenShare(stream = 0) {
    var {
          activeRoom,
          videoMuted,
          videoDeviceId,
          videoDevices
        }                 = this.props,
        localParticipant  = U.get(activeRoom, 'localParticipant');

    VideoUtils.removeLocalParticipantTracks(localParticipant, 'video');

    if (this.state.sharingScreen || !stream) {
      // Will need to just remove videoTracks with trackName 'screenshare' when we start having multiple videoTracks
      if (!videoMuted) {
        try {
          var localVideoTrack = await Twilio.createLocalVideoTrack({
            deviceId: (videoDeviceId != null && videoDevices.length) ? { exact: videoDevices[videoDeviceId].id } : null,
          });

          await localParticipant.publishTrack(localVideoTrack);
          await this.toggleBackground(this.state.backgroundType, BACKGROUND_IMAGE_NONE);
        } catch(error) {
          console.log(error);
        }
      }

      this.setState({ sharingScreen: false, screenShareId: null, presentation: null });
    } else {
      var streamId    = stream.id,
          screenTrack = stream.getVideoTracks()[0];

      await localParticipant.publishTrack(screenTrack, { name: 'screenshare' });
      this.setState({ sharingScreen: true, screenShareId: streamId });
    }
  }

  changePresentationTrack(sid) {
    var participants    = U.get(this.props.activeRoom, 'participants'),
        addNotification = this.props.addNotification;

    if (!participants)
      return;

    participants.forEach((participant) => {
      if (sid !== participant.sid)
        return;

      var participantPublication = null;

      participant.videoTracks.forEach((publication) => {
        // We only have one publication per participant. Will have to specify when we have more.
        participantPublication = publication;
      });

      if (participantPublication) {
        this.setState({ presentation: participantPublication })
      } else {
        U.callPropFunction(addNotification, ['Selected User Has No Video To Present.', 'negative']);
      }
    });
  };

  enterPresentationMode(participantSid) {
    if (!participantSid)
      return;

    this.setState({ screenMode: 'presentation' });
    this.changePresentationTrack(participantSid);
  }

  leavePresentationMode() {
    var participants    = U.get(this.props.activeRoom, 'participants'),
        changeToDefault = true;

    if (!participants)
      return;

    participants.forEach((participant) => {
      participant.videoTracks.forEach((publication) => {
        if (publication.trackName !== 'screenshare' || !publication.track)
          return;

        changeToDefault = false;

        var presentationContainer = document.getElementById('presentation-media');

        if (presentationContainer && !presentationContainer.getElementsByTagName('video').length)
          this.setState({ presentation: publication });
      });
    });

    if (changeToDefault)
      this.setState({ screenMode: 'default', presentation: null });
  };

  async toggleAudioDevice(_deviceId) {
    if (_deviceId === this.props.audioDeviceId) {
      return;
    }
    var deviceId          = Number.isInteger(_deviceId) ? _deviceId : (this.props.audioDeviceId || 0),
        audioDevices      = this.props.audioDevices || [],
        audioDeviceId     = (deviceId <= audioDevices.length - 1) ? deviceId : null,
        audioDevice       = (audioDeviceId !== null) ? audioDevices[audioDeviceId] : null,
        audioMuted        = this.props.audioMuted,
        localParticipant  = U.get(this.props.activeRoom, 'localParticipant');

    U.callPropFunction(this.props.updateSessionState, [{ audioDeviceId }]);

    if (!localParticipant)
      return;

    if (!audioMuted && audioDevice) {
      try {
        VideoUtils.removeLocalParticipantTracks(localParticipant, 'audio');
        var localAudioTrack = await Twilio.createLocalAudioTrack({
          deviceId: { exact: audioDevices[audioDeviceId].id },
        });

        await localParticipant.publishTrack(localAudioTrack);
      } catch (error) {
        console.log(error);
      }
    }
  }

  // change camera
  async toggleVideoDevice(_deviceId) {
    if (_deviceId === this.props.videoDeviceId) {
      return;
    }

    var deviceId          = Number.isInteger(_deviceId) ? _deviceId : (this.props.videoDeviceId || 0),
        videoDevices      = this.props.videoDevices || [],
        videoDeviceId     = (deviceId <= videoDevices.length - 1) ? deviceId : null,
        videoDevice       = (videoDeviceId !== null) ? videoDevices[videoDeviceId] : null,
        videoMuted        = this.props.videoMuted,
        localParticipant  = U.get(this.props.activeRoom, 'localParticipant');

    U.callPropFunction(this.props.updateSessionState, [{ videoDeviceId }]);

    if (!localParticipant || this.state.sharingScreen)
      return;

    if (!videoMuted && videoDevice) {
      try {
        var localVideoTrack = await Twilio.createLocalVideoTrack({ deviceId: { exact: videoDevice.id } });
        VideoUtils.removeLocalParticipantTracks(localParticipant, 'video');
        await localParticipant.publishTrack(localVideoTrack);
        await this.toggleBackground(this.state.backgroundType, BACKGROUND_IMAGE_NONE);
      } catch(error) {
        console.log(error);
      }
    }
  }

  async participantConnected(participant, recentlyConnected) {
    var response = await apiRequest('get', `participants/${participant.identity}/snapshot`);

    if (!response || !response.user)
      return;

    var user        = response.user,
        users       = [ ...this.state.users, user ],
        remoteCount = users.length;

    this.setState({ users, remoteCount });

    if (recentlyConnected) {
      var message = (user.isAdmin) ? user.name + ', the host, has joined' : user.name + ' has joined.';
      this.props.addNotification(message, 'positive');
    }
  }

  removeUser(participant) {
    var userToRemove = this.state.users.find((user) => (user.id === parseInt(participant.identity)));
    if (!userToRemove)
      return;

    var name        = U.get(userToRemove, 'name'),
        isHost      = (U.get(userToRemove, 'isAdmin')) ? ', the host,' : '',
        message     = `${name + isHost} has left.`,
        newUserList = (this.state.users || []).filter((user) => user.id !== userToRemove.id),
        remoteCount = newUserList.length;

    this.props.addNotification(message, 'neutral');
    this.setState({ users: newUserList, remoteCount });
  }

  renderEmptyRoomBackground() {
    return (
      <div className="room-empty">
        <h1>Nobody is currently in this room</h1>
        <button data-test-id="invite-people" onClick={this.toggleInvite}>Invite People</button>
      </div>
    )
  };

  renderLocalPIP() {
    var {
          audioMuted,
          videoDevices,
          videoMuted
        }                   = this.props,
        sharingScreen       = this.state.sharingScreen,
        participant         = U.get(this.props.activeRoom, 'localParticipant'),
        missingVideoDevice  = ((videoDevices || []).length === 0);

    return (
      <LocalMedia
        audioMuted={audioMuted}
        attachAudioLevelListener={this.attachAudioLevelListener}
        sharingScreen={sharingScreen}
        missingVideoDevice={missingVideoDevice}
        participant={participant}
        toggleScreenShare={this.toggleScreenShare}
        videoMuted={videoMuted}
      />
    );
  };

  renderRemoteMedia(participants) {
    var { screenMode, users } = this.state,
        isAdmin               = this.props.isAdmin;

    if (!users.length || !participants)
      return;

    return Array.from(participants.values(), (participant) => {
      var identity  = parseInt(participant.identity),
          user      = users.find((user) => (user.id === identity));

      return (
        <ParticipantMedia
          key={identity}
          participant={participant}
          user={user}
          changePresentationTrack={this.changePresentationTrack}
          currentUserIsAdmin={isAdmin}
          onEnterPresentation={this.enterPresentationMode}
          onLeavePresentation={this.leavePresentationMode}
          screenMode={screenMode}
        />
      )
    });
  }

  renderMediaComponents() {
    var {
          layout,
          remoteCount,
          controlsVisible,
          muteWarning,
          screenMode,
          sharingScreen,
          settingsPanel,
          presentation
        }                   = this.state,
        participants        = U.get(this.props.activeRoom, 'participants', null),
        presentationMode    = (screenMode === 'presentation');

    return (
      <div className="media-wrapper">
        {!!presentationMode && (
          <PresentationContainer
            presentation={presentation}
            onLeavePresentation={this.leavePresentationMode}
          />
        )}

        {!layout && this.renderLocalPIP()}

        <div
          className={generateClassNames(
            'remote-media',
            'count-' + remoteCount,
            (presentationMode) ? 'presentation' : null
          )}
          id="remote-media"
        >
          {this.renderRemoteMedia(participants)}
          {(layout === 'grid') && <div id="local-media"></div>}
        </div>

        {remoteCount === 0 && this.renderEmptyRoomBackground()}

        <VideoControls
          controlsVisible={controlsVisible || muteWarning}
          sharingScreen={sharingScreen}
          videoMuted={this.props.videoMuted}
          audioMuted={this.props.audioMuted}
          muteWarning={muteWarning}
          settingsPanel={settingsPanel}
          onToggleMuteVideo={this.toggleMuteVideo}
          onToggleMuteAudio={this.toggleMuteAudio}
          onToggleInvite={this.toggleInvite}
          onFlipMobileCamera={this.flipMobileCamera}
          onToggleScreenShare={this.toggleScreenShare}
          onGetScreenShare={this.getScreenShare}
          onLeaveRoom={this.leaveRoomInit}
          updateState={(updates) => this.setState(updates)}
        />
      </div>
    );
  }

  render() {
    var {
          invitePanel,
          leaveRoomModal,
          networkQualityLevel,
          settingsPanel,
          sentInvites,
          timeoutPanel,
          remoteCount,
          users,
          deniedUsers,
          backgroundType,
          backgroundImageSelected,
        } = this.state;

    return (
      <React.Fragment>

        <div className="connect-quality" style={{ visibility: 'hidden', pointerEvents: 'none' }}>
          {networkQualityLevel}
        </div>

        {this.renderMediaComponents()}

        {leaveRoomModal && (
          <LeaveRoomModal
            emptyRoom={(remoteCount < 1 || this.props.appId)}
            onClose={() => this.setState({ leaveRoomModal: false })}
            onFlushRoom={this.flushRoom}
            onLeaveRoom={this.leaveRoomAndTrackEvents}
          />
        )}

        {settingsPanel && (
          <SettingsPanel
            videoDevices={this.props.videoDevices}
            audioDevices={this.props.audioDevices}
            videoDeviceId={this.props.videoDeviceId}
            audioDeviceId={this.props.audioDeviceId}
            backgroundType={backgroundType}
            backgroundImageSelected={backgroundImageSelected}
            isAdmin={this.props.isAdmin}
            backgroundImageNames={Array.from(this.props.backgroundImagesMap.keys())}
            pathImages={this.props.pathImages}
            onToggleVideoDevice={this.toggleVideoDevice}
            onToggleAudioDevice={this.toggleAudioDevice}
            onClose={() => this.setState({ settingsPanel: false })}
            onToggleBackground={this.toggleBackground}
          />
        )}

        {invitePanel && !timeoutPanel && (
          <FeatureInvitePanel
            addNotification={this.props.addNotification}
            displayName={this.props.displayName}
            inviteToken={this.props.inviteToken}
            userIdUnique={this.props.userIdUnique}
            isAdmin={this.props.isAdmin}
            remoteCount={remoteCount}
            roomInviteUrl={this.props.roomInviteUrl}
            currentUserId={this.props.userId}
            sentInvites={sentInvites}
            users={users}
            deniedUsers={deniedUsers}
            denyAccess={this.props.denyAccess}
            updateHost={this.props.updateHost}
            sessionName={this.props.sessionName}
            updateInvites={this.updateUsers}
            toggleInvite={this.toggleInvite}
            videoRoomId={this.props.videoRoomId}
          />
        )}

        {(timeoutPanel) && (
          <PageTimeoutModal
            onTimeExpired={this.leaveRoom}
            onCancelTimeout={this.cancelPageTimeout}
          />
        )}
      </React.Fragment>
    )
  }
}

export default VideoChatPage;
