import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import generateClassNames   from 'classnames';
import { isMobile }         from 'react-device-detect';
import { isFunction }       from '../utils/tools';

class VideoControls extends Component {
  static propTypes = {
    controlsVisible:      PropTypes.bool,
    sharingScreen:        PropTypes.bool,
    videoMuted:           PropTypes.bool,
    audioMuted:           PropTypes.bool,
    muteWarning:          PropTypes.bool,
    onToggleMuteVideo:    PropTypes.func,
    onToggleMuteAudio:    PropTypes.func,
    onToggleInvite:       PropTypes.func,
    onFlipMobileCamera:   PropTypes.func,
    onToggleScreenShare:  PropTypes.func,
    onGetScreenShare:     PropTypes.func,
    onLeaveRoom:          PropTypes.func,
    updateState:          PropTypes.func
  };

  getIconButtons() {
    var {
          videoMuted,
          audioMuted,
          muteWarning,
          onGetScreenShare,
          onFlipMobileCamera,
          onLeaveRoom,
          onToggleMuteVideo,
          onToggleMuteAudio,
          onToggleInvite,
          onToggleScreenShare,
          settingsPanel,
          sharingScreen
        } = this.props,
        audioLabel;

    if (!audioMuted) {
      audioLabel = 'Mute Mic';
    } else {
      audioLabel = (!muteWarning)
        ? 'Enable Mic'
        : 'Are you talking? Your mic is muted. Click the mic to turn it on.';
    }

    return [
      {
        label:      (videoMuted) ? 'Turn on camera' : 'Turn off Camera',
        icon:       (videoMuted) ? 'icon-video-off' : 'icon-video',
        extraClass: (videoMuted) ? 'selected' : null,
        onClick:    () => setTimeout(() => onToggleMuteVideo(), 5),
        shouldShow: true
      },
      {
        label:      audioLabel,
        icon:       (audioMuted) ? 'icon-mic-off' : 'icon-mic',
        extraClass: generateClassNames((audioMuted) && 'selected', (muteWarning) && 'mute-warning'),
        onClick:    () => setTimeout(() => onToggleMuteAudio(), 5),
        shouldShow: true
      },
      {
        label:      (sharingScreen) ? 'Stop Sharing' : 'Share Screen',
        icon:       'icon-screenshare',
        extraClass: (sharingScreen) ? 'sharing-screen' : null,
        onClick:    (!sharingScreen) ? onGetScreenShare : onToggleScreenShare,
        shouldShow: !isMobile
      },
      {
        icon:       'icon-camera-switch',
        onClick:    onFlipMobileCamera,
        shouldShow: isMobile,
      },
      {
        testId:     'inviteButton',
        label:      'Invite',
        icon:       'icon-user',
        onClick:    onToggleInvite,
        shouldShow: true,
      },
      {
        label:      (!settingsPanel) ? 'Open Settings' : 'Close Settings',
        icon:       'icon-settings',
        onClick:    () => this.props.updateState({ settingsPanel: !settingsPanel }),
        shouldShow: !isMobile,
      },
      {
        label:      'Leave Room',
        icon:       'icon-phone',
        onClick:    onLeaveRoom,
        shouldShow: true,
      },
    ];
  }

  renderIconButtons() {
    return this.getIconButtons().map((button, index) => {
      if ((isFunction(button.shouldShow)) ? !button.shouldShow() : !button.shouldShow)
        return null;

      return (
        <button
          data-test-id={button.icon}
          className={generateClassNames('control-btn', button.extraClass)}
          key={`control-btn-${index}`}
          onClick={(isFunction(button.onClick)) ? button.onClick : null}
          data-testid={button.testId}
        >
          {(!!button.icon) && <i className={button.icon}/>}

          {(!!button.label) &&
            <span className="control-btn-label">
              <label>{button.label}</label>
            </span>
          }
        </button>
      );
    })
  }

  render() {
    return (
      <div className={generateClassNames('controls', isMobile && 'mobile', this.props.controlsVisible && 'visible')}>
        {this.renderIconButtons()}
      </div>
    );
  }
};

export default VideoControls;
