import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { isMobile }         from 'react-device-detect';
import U                    from '../../utils/tools';

class MediaMissingModal extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    U.bindFunctions(this);
  }
  static propTypes = {
    cameraMissing:      PropTypes.bool,
    microphoneMissing:  PropTypes.bool,
    onOpenHelpModal:    PropTypes.func
  };

  _onOpenHelpModal() {
    U.callPropFunction(this.props.onOpenHelpModal);
  }

  render() {
    var { cameraMissing, microphoneMissing } = this.props;

    return (
      <div className="modal mobile-modal media-devices-missing-modal">
        <div className="modal-content">
          <div className="modal-body center">
            <h1 className="title center">Looks like something is missing</h1>
            <h6>We were unable to locate the following:</h6>

            <div className="device-list">
              {(!!cameraMissing) && (
                <div className="device camera">
                  <div className="container-icon"><i className="icon-video"/></div>
                  <span>Camera</span>
                </div>
              )}

              {(!!microphoneMissing) && (
                <div className="device microphone">
                  <div className="container-icon"><i className="icon-mic"/></div>
                  <span>Microphone</span>
                </div>
              )}
            </div>

            <h6>
              Please make sure you have a camera and microphone{(!isMobile) && <br/>} available on your device, then try again.
            </h6>

            <button data-test-id="need-assistance-media-missing" className="link" onClick={this._onOpenHelpModal}>
              Need further assistance?
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default MediaMissingModal;
