import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import U                      from '../../utils/tools';

class ParticipantActionsModal extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      showActions: false
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    U.bindFunctions(this);
  }

  static propTypes = {
    user: PropTypes.object,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("touchstart", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("touchstart", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (!this.wrapperRef || !this.wrapperRef.current) {
      return;
    }
    if (!this.wrapperRef.current.contains(event.target)) {
      this.setState({ showActions: false });
    }
  }

  toggleDropdown() {
    this.setState({ showActions: !this.state.showActions });
  }

  updateHost(user) {
    U.callPropFunction(this.props.updateHost, [user, !user.isAdmin]);
    this.toggleDropdown();
  }

  denyAccess(user) {
    U.callPropFunction(this.props.denyAccess, [user]);
    this.toggleDropdown();
  }

  render() {
    let { user } = this.props,
        { showActions } = this.state;

    return (
      <div>
        <div className="joined-participants-actions" ref={this.wrapperRef}>
          <div className="dropdown-button" onClick={this.toggleDropdown}>
            <i className="icon-vertical-dots" />
          </div>
          { showActions && 
            (<div className="dropdown-content">
              <span onClick={() => this.updateHost(user)}>
                {user.isAdmin ? <i className="icon-user-md-dash" /> : <i className="icon-user-md" />}
                {user.isAdmin ? 'Disable Host' : 'Make Host'}
              </span>
              <span onClick={() => this.denyAccess(user)}>
                <i className="icon-deny" />
                Deny Access
              </span>
            </div>
            )}
        </div>
      </div>
    );
  }
}

export default ParticipantActionsModal;
