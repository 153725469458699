import React, { Component }   from 'react';
import PropTypes              from 'prop-types';

class InformationModal extends Component {
  static propTypes = {
    header: PropTypes.string,
    body:   PropTypes.string
  };

  render() {
    var { header, body } = this.props;

    return (
      <div className="join-wrapper">
        <div className="waiting">
          <h1>{header}</h1>
          <p>{body}</p>
        </div>
      </div>
    );
  }
}

export default InformationModal;
