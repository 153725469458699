import React, { Component}  from 'react';
import PropTypes            from 'prop-types';
import generateClassNames   from 'classnames';
import Axios                from 'axios';
import { findIndex }        from 'lodash';
import { CopyToClipboard }  from 'react-copy-to-clipboard';
import PatientsGraphic      from '../images/graphic-patients.svg';
import Spinner              from '../images/loader-tail-spin.svg';
import U                    from '../utils/tools';
import ParticipantActionsModal from './modals/participant-actions-modal';

const PARTICIPANT_LIMIT = 8; // 8 participants + 1 host = 9 users

class InvitePanel extends Component {
  _isMounted = false;

  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      displayName:          props.displayName || '',
      inviteError:          null,
      inviteInput:          '',
      resendInviteId:       null,
      showInviteSpinner:    false,
    };

    U.bindFunctions(this);
  }

  static propTypes = {
    addNotification:    PropTypes.func,
    displayName:        PropTypes.string,
    inviteToken:        PropTypes.string,
    isAdmin:            PropTypes.bool,
    remoteCount:        PropTypes.number,
    roomInviteUrl:      PropTypes.string,
    sentInvites:        PropTypes.array,
    users:              PropTypes.array,
    deniedUsers:        PropTypes.array,
    sessionName:        PropTypes.string,
    toggleInvite:       PropTypes.func,
    updateInvites:      PropTypes.func,
    userIdUnique:       PropTypes.string,
    videoRoomId:        PropTypes.number
  };

  componentDidMount() {
    this._isMounted = true;

    U.callPropFunction(this.props.updateInvites);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async sendInvite(invitee) {
    var {
          inviteToken,
          sessionName,
        }               = this.props,

        data            = {
          participant: invitee,
          inviteToken,
          sessionName,
        };

    return Axios.post(`${process.env.REACT_APP_URL_API}/api/video-rooms/${this.props.videoRoomId}/send-invite`, data, {
      withCredentials: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }

  async handleSendInvite() {
    var {
      numberOfParticipants,
    }               = this.props,
    roomFull        = ((numberOfParticipants + this.props.sentInvites.length) >= PARTICIPANT_LIMIT);

    if (roomFull)
      U.callPropFunction(this.props.addNotification, [`Only ${PARTICIPANT_LIMIT} participants allowed.`, 'negative']);

    var invitee = this.validateInviteInput(this.state.inviteInput);

    if (!invitee) {
      return U.callPropFunction(this.props.addNotification, ['Unable to validate invite information', 'negative']);
    }

    try {
      this.setState({ showInviteSpinner: true });
      await this.sendInvite(invitee);

      U.callPropFunction(this.props.addNotification, ['Invite has been sent.', 'positive']);

      if (this._isMounted) {
        this.setState({
          showInviteSpinner:  false,
          inviteInput: ''
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        this.setState({ showInviteSpinner: false, inviteError: error.response.data.message });
      } else {
        this.callPropFunction(this.props.toggleInvite)
      }
    }
  }

  async handleResendInvite(invitee) {
    this.setState({
      resendInviteId: invitee.id
    });

    try {
      await this.sendInvite(invitee);

      U.callPropFunction(this.props.addNotification, ['Invite has been sent.', 'positive']);

      if (this._isMounted) {
        this.setState({
          resendInviteId:  null,
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        this.setState({ showInviteSpinner: false, inviteError: error.response.data.message });
      } else {
        U.callPropFunction(this.props.toggleInvite)
      }
    }
  }

  validateInviteInput(input) {
    var sentInvites       = this.props.sentInvites,
        emailFormatInput  = U.formatEmailValue(input),
        phoneFormatInput  = U.formatPhoneValue(input);

    if (U.INTERNATIONAL_PHONE_REGEX.test(phoneFormatInput) && !U.PHONE_REGEX.test(phoneFormatInput))
      U.callPropFunction(this.props.addNotification, ["Only USA numbers are supported.", 'negative']);

    if (U.EMAIL_REGEX.test(emailFormatInput)) {
      var sentInviteIndexByEmail = findIndex(sentInvites, (sentInvite) => sentInvite.email === emailFormatInput);

      if (sentInviteIndexByEmail > -1) {
        this.setState({ inviteError: 'Email invite already exists' });
        return false;
      } else {
        return { name: 'default', invite: 'email', email: emailFormatInput };
      }
    }

    if (U.PHONE_REGEX.test(phoneFormatInput)) {
      if (phoneFormatInput.length === 11) {
        phoneFormatInput = "+" + phoneFormatInput;
      } else if (phoneFormatInput.length === 10) {
        phoneFormatInput = "+1" + phoneFormatInput;
      }

      var sentInviteIndexByPhone  = findIndex(sentInvites, (sentInvite) => sentInvite.phone === phoneFormatInput),
          phoneParts              = {
            one:    phoneFormatInput.substring(0, 2),
            two:    phoneFormatInput.substring(2, 5),
            three:  phoneFormatInput.substring(5, 8),
            four:   phoneFormatInput.substring(8, 12)
          };

      if (sentInviteIndexByPhone > -1) {
        this.setState({ inviteError: 'Phone number invite already exists' });
        return false;
      } else {
        return { name: 'default', invite: 'sms', phone: phoneFormatInput, phoneParts };
      }
    }

    this.setState({ inviteError: 'Enter a valid phone number or email address' });
    return false;
  }

  getSentInviteList() {
    var { resendInviteId } = this.state;

    return this.props.sentInvites.map((user, key) => {
      var formattedPhone    = this.getFormattedPhone(user),
          email             = user.email || 'no name',
          showInviteSpinner = resendInviteId === user.id,
          disableButton     = resendInviteId !== null;

      return (
        <div className="invite-contact resend-invite" id={key} key={key}>
          <div className="invite-container">
            <div className="invite-value">{(user.phone) ? formattedPhone : email}</div>
            {(this.props.isAdmin) && (
              <>
                <div className="icon-btn" onClick={() => U.callPropFunction(this.props.denyAccess, [user])}>
                  <i className="icon-deny" />
                </div>
                <button data-test-id="resend-user-invite" disabled={disableButton} className="input-add resend-btn" onClick={() => this.handleResendInvite(user)}>
                  {showInviteSpinner ? <img className="spinner" src={Spinner} alt="loader"/> : 'Resend' }
                </button>
              </>
            )}
          </div>
        </div>
      )
    });
  }

  getUsersList(showDeniedUsers = false) {
    const users = (showDeniedUsers) ? this.props.deniedUsers : this.props.users;
    const { resendInviteId } = this.state;

    return users.map((user, key) => {
      var inviteePhone  = this.getFormattedPhone(user),
          inviteeEmail  = U.get(user, 'email'),
          showInviteSpinner = resendInviteId === user.id,
          disableButton     = resendInviteId !== null;

      var joinMethod = 'Share Link';

      if (user.isAdmin)
        joinMethod = 'Host';
      else if (inviteePhone)
        joinMethod = inviteePhone;
      else if (inviteeEmail)
        joinMethod = inviteeEmail;

      return (
        <div className="invite-contact resend-invite" id={key} key={key}>
          <div className="invite-container">
            <div className="invite-value">
              {`${user.name} (${joinMethod})`}
            </div>
            {(this.props.isAdmin && !showDeniedUsers) && (
              <ParticipantActionsModal 
              user={user}
              denyAccess={this.props.denyAccess}
              updateHost={this.props.updateHost}
              />
            )}
            {(this.props.isAdmin && showDeniedUsers) && (
              <button data-test-id="allow-denied-user" disabled={disableButton} className="input-add resend-btn" onClick={() => this.handleResendInvite(user)}>
                {showInviteSpinner ? <img className="spinner" src={Spinner} alt="loader"/> : 'Allow' }
              </button>
            )}
          </div>
        </div>
      );
    })
  }

  getFormattedPhone(item) {
    if (!item || !item.phone)
      return null;

    var formattedPhone = item.phone;

    if (formattedPhone.indexOf('*') < 0) {
      if (!item.phoneParts) {
        var phoneFormatInput = U.formatPhoneValue(item.phone);
        item.phoneParts = {
          one:    phoneFormatInput.substring(0, 2),
          two:    phoneFormatInput.substring(2, 5),
          three:  phoneFormatInput.substring(5, 8),
          four:   phoneFormatInput.substring(8, 12)
        };
      }

      formattedPhone = '(' + item.phoneParts.two + ') ' + item.phoneParts.three + '-' + item.phoneParts.four;
    }

    return formattedPhone;
  }

  renderInviteForm() {
    var {
          inviteInput,
          inviteError,
        }               = this.state,
        {
          sentInvites,
          users,
          deniedUsers,
          roomInviteUrl
        }               = this.props,
        invitesPresent  = (sentInvites && sentInvites.length  > 0);

    const onCloseInvitePanel = () => {
      U.callPropFunction(this.props.toggleInvite)
    };

    const inviteChange = (event) => {
      var inviteInput = event.target.value;
      this.setState({ inviteInput, inviteError: null });
    };

    return (
      <div className="modal-content invite">
        <div className="modal-body">
          <div className="close-modal icon-btn" onClick={onCloseInvitePanel}>
            <i className="icon-close"></i>
          </div>

          <h1>Invite Participants</h1>
          <p><small>Provide an email address or phone number</small></p>

          <div className="invite-form">
            <div className="input-group">
              <input
                className={generateClassNames((inviteInput !== '') ? 'entered' : null)}
                type="text"
                name="emails"
                value={inviteInput}
                onKeyDown={(event) => (event.key === 'Enter') ? this.handleSendInvite() : null}
                onChange={inviteChange}
                data-testid="emailOrPhoneInput"
              />
              <label>Enter email or phone</label>

              <button data-test-id="send-invite" className="input-add send-btn-input" onClick={this.handleSendInvite} data-testid="addInviteButton">
                {this.state.showInviteSpinner ? <img className="spinner" src={Spinner} alt="loader"/> : 'Send Invite'}
              </button>
            </div>

            {(!!inviteError) && <p className="error">{inviteError}</p>}
          </div>

          {(sentInvites && sentInvites.length > 0) &&
            <div className="invite-list sent">
              <p className="meta">Invited Participants</p>
              {this.getSentInviteList()}
            </div>
          }

          {(users && users.length > 0) &&
            <div className="invite-list sent">
              <p className="meta">Joined Participants</p>
              {this.getUsersList()}
            </div>
          }

          {(this.props.isAdmin && deniedUsers && deniedUsers.length > 0) &&
            <div className="invite-list sent">
              <p className="meta">Denied Participants</p>
              {this.getUsersList(true)}
            </div>
          }

          {!invitesPresent &&
            <div className="invite-empty">
              <img src={PatientsGraphic} alt="Empty state"/>
              <p>Invite participants to join this meeting!</p>
            </div>
          }
        </div>

        {(!!roomInviteUrl) &&
          <div className="modal-footer">
            <h5>Share the Link</h5>

            <div className="copy-link">
              <input
                className="default"
                type="text"
                name="room-invite-url"
                readOnly={true}
                value={roomInviteUrl}
              />
              <CopyToClipboard
                text={roomInviteUrl}
                onCopy={() => U.callPropFunction(this.props.addNotification, ['Invite link copied.', 'positive'])}
                options={{ format: 'text/plain' }}>
                <button data-test-id="copy-room-invite-url">Copy</button>
              </CopyToClipboard>
            </div>
          </div>
        }
      </div>
    )
  }

  render() {
    return (
      <div className={generateClassNames('modal')}>
        { this.renderInviteForm() }
      </div>
    );
  }
};

export default InvitePanel;
