import Axios from 'axios';

const axiosClient = Axios.create({
  baseURL: `${process.env.REACT_APP_URL_API}/api/`,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

// cookies are more secure than session storage,
// but in case cookies are disabled on the client,
// we fallback to session storage
export function storeAuthorizationToken(token) {
  if (navigator.cookieEnabled || !token) {
    return;
  }

  window.sessionStorage.setItem('jwtToken', token);
}

function getAuthorizationToken() {
  return window.sessionStorage.getItem('jwtToken');
}

export async function apiRequest(method, relativePath, params = null) {
  const authToken = getAuthorizationToken();

  if (authToken) {
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  }

  const response = await axiosClient.request({
    method,
    url: relativePath,
    data: params,
  });

  return response.data;
}
