import React, { Component }       from 'react';
import {
  isDesktop,
  isMobile,
  isChrome,
  isFirefox,
  isEdge,
  isSafari,
  browserVersion,
  isMacOs,
  isWindows,
  isIOS,
  isAndroid
}                                 from 'react-device-detect';
import Axios                      from 'axios';
import Twilio                     from 'twilio-video';
import classNames                 from 'classnames';
import { createBrowserHistory }   from 'history';
import { findIndex }              from 'lodash';
import { v1 as uuidv1 }           from 'uuid';
import Spinner                    from './images/loader-tail-spin.svg';
import ExpressLogo                from './images/evisit-express-logo.png';
import SplashLogo                 from './images/evisit-splash-logo.svg';
import U                          from './utils/tools';
import { 
  addGainsightContext,
  identify,
  page, 
  track as trackEvent,
}                                 from './analytics';
import { VISIT_END_EVENT }        from './utils/analytics-events';
import { 
  getSessionAudioID, 
  deleteSessionAudioID, 
  getSessionVideoID,
  deleteSessionVideoID
}                                 from './utils/storage'
import { 
  apiRequest, 
  storeAuthorizationToken, 
}                                 from './utils/express-api';
import pusherTools                from './utils/pusher';
import JoinRequestAlertSound      from './audio/visit-join-request-sound.mp3';

import WaitingRoomPage            from './components/waiting-room-page';

import VideoChatPage              from './components/video-chat-page';

import './scss/styles.scss';

import * as Comlink       from 'comlink'; 

/* eslint-disable import/no-webpack-loader-syntax */
import Worker from "worker-loader!./app.worker.js";

const EVISIT_LOGIN_URL  = `https://${process.env.REACT_APP_EVISIT_ENV}.evisit.com/r/`,
      SPLASH_DURATION   = 1000;

function createResolvable() {
  var resolve, reject, promise = new Promise((_resolve, _reject) => {
    resolve = _resolve;
    reject  = _reject;
  });

  promise.resolve = resolve;
  promise.reject  = reject;

  return promise;
}

const worker    = new Worker();
const instance  = Comlink.wrap(worker);

class App extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.getUrlParams = new URLSearchParams(window.location.search);

    Object.assign(this, {
      appId:                    this.getUrlParams.get('aid'),
      parentOrigin:             this.getUrlParams.get('or'),
      interAppMessageIdCounter: 1,
      interAppMessagePromises:  {},
      isStateReady: false
    });

    this.state = {
      activeRoom:             null,
      audioDeviceId:          null,
      audioDevices:           [],
      audioMuted:             false,
      autoJoin:               U.guessBooleanFromString(this.getUrlParams.get('aj')),
      browserApproved:        null,
      displayName:            null,
      displayNameInput:       '',
      durationTimes:          {},
      endedAt:                null,
      entryApproved:          false,
      evisitId:               null,
      hasDeviceList:          false,
      history:                createBrowserHistory(),
      hostEnded:              false,
      initPing:               null,
      inviteExpired:          false,
      inviteToken:            this.getUrlParams.get('i') || null,
      isAdmin:                null,
      isDenied:               false,
      knockFirst:             false,
      layout:                 this.getUrlParams.get('layout') || null,
      localTracks:            null,
      mediaBlocked:           null,
      mediaMissing:           null,
      nameError:              null,
      notificationCount:      0,
      notifications:          [],
      requestPending:         false,
      roomConnected:          false,
      roomInviteToken:        null,
      roomInviteUrl:          null,
      roomLive:               false,
      roomSwitch:             false,
      sessionName:            null,
      showJoinSpinner:        false,
      showSplashScreen:       true,
      showWaitingPage:        true,
      startedAt:              null,
      canRejoinVisit:         false,
      twilioId:               null,
      twilioRoomName:         null,
      twilioToken:            null,
      tokenInUse:             false,
      userId:                 null,
      userIdUnique:           uuidv1(),
      videoDeviceId:          null,
      videoDevices:           [],
      videoMuted:             false,
      videoRoomId:            null,
      visitEnded:             false,
      backgroundImagesMap:   new Map(),
      pathImages:             null,
      practiceId:             null,

      // FEATURE FLAGS
    };

    U.bindFunctions(this);
    this.setupInterAppCommunication();
  }

  componentDidMount() {
    // i for (invite) comes from url
    if (this.state.inviteToken)
      this.getInvite();

    // body class for mobile styling
    if (isMobile)
      document.body.classList.add('mobile');

    // browser adjustments
    this.isBrowserApproved();

    if (this.state.browserApproved)
      this._updateDeviceLists();

    // listen for device list updates
    if (navigator.mediaDevices && U.isFunction(navigator.mediaDevices.addEventListener))
      navigator.mediaDevices.addEventListener('devicechange', this._updateDeviceLists);
  }

  // TODO: Can probably make this smarter simply by sticking to a standard pattern for our domains
  getInterAppCommunicationOrigin() {
    return this.parentOrigin;
  };

  setupInterAppCommunication() {
    // Are we in a frame/child window?
    if (window.self === window.parent || window.eVisitExpressInterAppCommunication)
      return;

    window.eVisitExpressInterAppCommunication = true;

    const overloadConsole = () => {
      const overloadConsoleMethod = (method) => {
        var self = this;

        return function() {
          var args = new Array(arguments.length);
          for (var i = 0, il = arguments.length; i < il; i++)
            args[i] = arguments[i];

          self.sendInterAppSignal('console', { type: method, args: args });

          return originalConsole[method].apply(originalConsole, args);
        };
      };

      var originalConsole = window._console = window.console,
          newConsole      = window.console = Object.create(window.console);

      [ 'log', 'warn', 'error', 'debug' ].forEach((method) => {
        newConsole[method] = overloadConsoleMethod(method);
      });
    };

    const callHandler = async (eventName, handlerName, data) => {
      try {
        var handler = this[handlerName],
            result  = await handler.call(this, data);

        if (eventName !== 'result' && data.messageId) {
          //console.log(`SENDING RESPONSE TO PARENT APP[${data.messageId}]: `, result);
          this.sendInterAppSignal('result', { messageId: data.messageId, status: 1, result });
        }
      } catch (error) {
        if (eventName !== 'result' && data.messageId) {
          //console.log(`SENDING ERROR RESPONSE TO PARENT APP[${data.messageId}]: `, error);
          this.sendInterAppSignal('result', { messageId: data.messageId, status: 0, result: error.message });
        }
      }
    };

    const eventHandler = (event) => {
      if (event.origin !== this.getInterAppCommunicationOrigin())
        return;

      var data = event.data;
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (error) {

        }
      }

      var { eventName, appId } = data,
          handlerName          = `interAppMessage_${eventName}`;

      if (!App.prototype.hasOwnProperty(handlerName) || typeof this[handlerName] !== 'function')
        return;

      if (appId !== this.appId)
        return;

      callHandler(eventName, handlerName, data);
    };

    overloadConsole();

    window.addEventListener('message', eventHandler);
    document.addEventListener('message', eventHandler);

    setTimeout(() => {
      this.sendInterAppSignal('ready').then((result) => {
        // app ready
        console.log('APP READY! ', result);
      }, (error) => {
        // error
      });
    }, 250);
  };

  sendInterAppSignal(eventName, _data) {
    if (!this.appId)
      return;

    if (!eventName || typeof eventName !== 'string' || eventName.trim().length === 0)
      throw new TypeError("'eventName' must be a valid string");

    var targetOrigin = this.getInterAppCommunicationOrigin(),
        data         = Object.assign({}, _data || {}, { eventName, appId: this.appId, sourceApp: 'Express' }),
        promise,
        messageId;

    if (eventName !== 'result') {
      promise         = createResolvable();
      messageId       = this.interAppMessageIdCounter++;
      data.messageId  = messageId;

      this.interAppMessagePromises[messageId] = promise;
    }

    window.parent.postMessage(data, targetOrigin);

    return (promise) ? promise : Promise.resolve();
  };

  interAppMessage_result({ messageId, status, result }) {
    var promise = this.interAppMessagePromises[messageId];
    if (!promise)
      return;

    delete this.interAppMessagePromises[messageId];

    if (status)
      promise.resolve(result);
    else
      promise.reject(result);
  }

  isBrowserApproved() {
    const userAgent = navigator.userAgent;
    const isBrave = userAgent.toLowerCase().includes('brave');
    const isDuckDuckGo = userAgent.toLowerCase().includes('duckduckgo');

    var version         = browserVersion,
        browserApproved =
        (
            ((isDesktop || isIOS || isAndroid) && isChrome && (!isBrave || !isDuckDuckGo))
            || (isSafari && version > 11)
            || (isWindows && isEdge && version > 79)
            || ((isMacOs || isWindows) && isFirefox)
        );

    this.setState({ browserApproved });
  }

  // for adding general notifications
  // types 'warn' 'negative' 'neutral' 'positive'
  // room access requests to admin feed into notifications [] as well, but not here. they also have methods associated.
  addNotification(msg, type, time = 3000) {
    var notificationCount = this.state.notificationCount,
        notification = {
          name:   msg,
          toast:  type,
          id:     notificationCount,
        };

    this.setState({
      notifications:      [ notification, ...this.state.notifications ],
      notificationCount:  notificationCount + 1
    });

    setTimeout(() => this.removeNotification(notification), time);
  }

  removeNotification(notification) {
    var notifications     = this.state.notifications.filter((item) => (item.id !== notification.id)),
        notificationCount = notifications.length;

    this.setState({ notifications, notificationCount });
  }

  pusherInitChannels() {
    pusherTools.subscribeToInitChannels(this.state.sessionName, this.state.userIdUnique);

    pusherTools.pusherChannels.roomInitChannel.bind('room-live', (data) => {
      this.setState({ roomLive: true });
    });

    pusherTools.pusherChannels.userInitChannel.bind('room-approval', (msg) => {
      if (msg.approved) {
        this.addNotification('Access Granted', 'positive');
        this.transactRoomToken(this.state.userId);
      } else {
        this.roomRestricted(msg.restrictedReason);
        this.setState({ requestPending: false });
      }

      setTimeout(() => this.setState({ showJoinSpinner: false }), 100);
      setTimeout(() => this.setState({ showVideoSpinner: true }), 1000);
      setTimeout(() => this.setState({ showVideoSpinner: false }), 15000);
    });

    pusherTools.pusherChannels.roomInitChannel.bind('room-flush', (msg) => {
      const { isAdmin, videoRoomId } = this.state;
      this.setState({
        hostEnded:        true,
        roomConnected:    false,
        roomLive:         false,
        requestPending:   false,
        showJoinSpinner:  false,
        visitEnded:       true,
        durationTimes:    msg.durationTimes,
        endedAt:          msg.endedAt,
        startedAt:        msg.startedAt
      });

      pusherTools.unsubscribeToAllChannels();

      if (this.state.activeRoom) {
        this.addNotification('Call has ended.', 'neutral');
      }

      trackEvent(VISIT_END_EVENT, { isAdmin, videoRoomId });
    });

    this.pusherAdminChannel(this.state.isAdmin);
  }

  pusherAdminChannel(isAdmin) {
    pusherTools.unsubscribeToAdminChannels();
    if (isAdmin) {
      pusherTools.subscribeToAdminChannel(this.state.inviteToken);

      pusherTools.pusherChannels.adminChannel.bind('room-request', (msg) => {
        msg.request = msg.name + ' would like to join!';

        this.setState({ notifications: [ ...this.state.notifications, msg ] });

        var audioElement = document.getElementById('join-request-audio');
        if (audioElement)
          audioElement.play();
      });

      pusherTools.pusherChannels.adminChannel.bind('room-full-request', (msg) => {
        var message = msg.name + " tried to join the room, but it's full.";
        this.addNotification(message, 'neutral');
      });

      pusherTools.pusherChannels.adminChannel.bind('remove-notification', (notification) => {
        this.removeNotification(notification);
      });
    }
  }

  pusherStateChannels() {
    pusherTools.unsubscribeToStateChannels();
    pusherTools.subscribeToStateChannels(this.state.userId);

    pusherTools.pusherChannels.userStateChannel.bind('allow-user', () => {
      this.addNotification('You can now rejoin the visit', 'positive');
      this.setState({ canRejoinVisit: true, isDenied: false });
    });

    pusherTools.pusherChannels.userStateChannel.bind('deny-user', () => {
      this.roomRestricted('host-denied');
    });

    pusherTools.pusherChannels.userStateChannel.bind('update-host', (data) => {
      let { isAdmin } = data;
      if (isAdmin) {
        this.addNotification('You are now a host', 'positive');
      } else {
        this.addNotification('You are no longer a host', 'negative');
      }       

      this.setState({ isAdmin: isAdmin });
      
      this.pusherAdminChannel(isAdmin);
    });
  }

  // creates jwt
  authorize(tempWebToken) {
    Axios({
      method: 'put',
      url: `${process.env.REACT_APP_URL_API}/api/participants/${this.state.inviteToken}/authorize`,
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${tempWebToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      storeAuthorizationToken(response.data.jwt);
      this.setState({ entryApproved: response.data.isApproved });
    }).catch((error) => {
      return this.setState({ entryApproved: false });
    });
  }

  rejoinVisit() {
    this.transactRoomToken(this.state.userId)
  }

  // fired on init, gets room from invite token in URL
  getInvite() {
    Axios({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API}/api/participants/${this.state.inviteToken}`,
      withCredentials: true
    })
      .then(async (response) => {
        var data    = response.data,
            isAdmin = (data.isAdmin) ? data.isAdmin : false;

        const { practiceId } = data.videoRoom;
        this.setState({ practiceId });
        
        const evisitUserId  = data.evisitUserId;
        const participantId = data.id;
        addGainsightContext({
          roomId: data.videoRoom.id,
          twilioRoom: data.videoRoom.twilioUniqueName,
        });

        identify(participantId, {
          isAdmin, 
          practiceId,
          evisitUserId
        });

        page('express-visit', {
          isAdmin,
          featureFlags: data.featureFlags,
          expressId: data.id,
          roomId: data.videoRoom.id,
          twilioRoom: data.videoRoom.twilioUniqueName,
          hostEnded: !!data.videoRoom.endedAt,
          visitEnded: data.visitEnded,
          inviteExpired: data.inviteExpired,
        });

        if (this.state.browserApproved)
          this.getDevices();

        if (data.visitEnded) {
          return this.setState({
            autoJoin:         false,
            initPing:         true,
            isAdmin:          isAdmin,
            roomInviteToken:  data.roomInviteToken,
            visitEnded:       true,
            startedAt:        data.startedAt,
            endedAt:          data.endedAt,
            durationTimes:    data.durationTimes
          });
        }

        if (data.tokenInUse) {
          await apiRequest('post', 'video-rooms/room-leave', {
            id: data.id,
          });
          
          return window.location.reload();
        }

        if (data.inviteExpired) {
          // expired means in use, will then go to get the public video room share URL
          this.setState({
            inviteToken:    data.videoRoom.inviteToken,
            inviteExpired:  true,
            autoJoin:       false
          });

          this.state.history.push(`?i=${data.videoRoom.inviteToken}`);
          return this.getInvite();
        }

        this.setState({
          sessionName:            data.videoRoom.sessionName,
          roomInviteToken:        data.videoRoom.inviteToken,
          roomInviteUrl:          data.videoRoom.inviteUrl,
          twilioRoomName:         data.videoRoom.twilioUniqueName,
          videoRoomId:            data.videoRoom.id,
          hostEnded:              (data.videoRoom.endedAt) ? true : false,
          tokenInUse:             data.tokenInUse,
          isAdmin:                isAdmin,
          isDenied:               data.isDenied,
          inviteExpired:          false,
          roomLive:               data.videoRoom.roomLive,
          initPing:               true,
        });

        if (data.isApproved)
          this.setState({ knockFirst: true });

        if (data.roomInvite) {
          this.setState({
            entryApproved:  false,
            autoJoin:       false,
            hostEnded:      (data.videoRoom.endedAt) ? true : false,
            displayName:    '',
            inviteExpired:  false,
            roomInvite:     true
          });

          this.pusherInitChannels();
        } else {
          if (!this.state.userId)
            this.pusherInitChannels();

          if (data.isAdmin)
            this.authorize(data.tempWebToken);

          // got approved and was waiting
          if (this.state.requestPending && data.isApproved) {
            setTimeout(() => this.joinRoom(), 20);
          } else if (this.state.changeName) {
            // name input field was different than existing state name,
            setTimeout(() => {
              this.setState({ changeName: false });
              (isAdmin) ? this.joinRoom() : this.enterRequest();
            }, 20);
          }

          setTimeout(() => this.setState({ roomInit: true }), 300);

          this.setState({
            twilioToken:    data.twilioAccessToken,
            entryApproved:  data.isApproved,
            displayName:    (data.name === 'default') ? '' : data.name,
            userId:         data.id
          });

          if (this.state.autoJoin && data.isApproved)
            this.autoJoinRoom();

          this.pusherStateChannels();
        }
      })
      .catch((error) => {
        console.error(error);

        return this.setState({ inviteExpired: true });
      });
  }

  autoJoinRoom() {
    this.setState({ showVideoSpinner: true });
    this.enterRequest();
  }

  // trades in old Twilio token for new one
  switchRooms() {
    var data = { twilioUniqueName: this.state.twilioRoomName };

    Axios.post(`${process.env.REACT_APP_URL_API}/api/participants/${this.state.inviteToken}/renew`, data, {
      withCredentials: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      var data = response.data;

      // got new token, go to room
      setTimeout(() => this.enterRequest(), 10);

      // set state and new URL first
      this.state.history.push(`?i=${data.inviteToken}`);

      this.setState({
        inviteToken:    data.inviteToken,
        twilioRoomName: data.videoRoom.twilioUniqueName,
        twilioToken:    data.twilioAccessToken,
        videoRoomId:    data.videoRoom.id,
        userId:         data.id,
        roomSwitch:     false,
      });
    })
      .catch((error) => {
        return this.setState({ inviteToken: null });
      });
  }

  async joinRoom() {
    var localTracks = this.state.localTracks || await this.getDevices();

    if (this.state.twilioToken && !this.state.roomConnected) {
      Twilio.connect(this.state.twilioToken, {
        name: this.state.twilioRoomName,
        tracks: localTracks,
        maxAudioBitrate: 16000,
        networkQuality: {
          local: 2, // LocalParticipant's Network Quality verbosity [1 - 3]
          remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
        },
        // Automatic switch on/off of the RemoteVideoTrack is managed by the SDK.
        bandwidthProfile: {
          video: {
            clientTrackSwitchOffControl: 'auto',
          }
        },
        // Apple released a Webkit regression in Safari 15.1
        // breaking publishing streams that use the H.264 codec.
        // Twilio recommends moving to the VP8 codec.
        // These codecs have wider browser support.
        // Adaptative Simulcast, will be used except if the publisher is using Firefox.
        // In that case, the unicast will be used.
        preferredAudioCodecs: [ 'OPUS' ],
        preferredVideoCodecs: [{codec: "VP8", simulcast: true, adaptiveSimulcast: true}]
      }).then(async (room) => {
        // have permissions if we get here for camera and mic
        await this._updateDeviceLists();
        await this.onRoomConnected(room);
      }, (error) => {
        console.log('Error when trying to connect (Twilio)', error);

        // check if we have permissions
        if (error.message.toLowerCase().indexOf('permission') > -1 || error.message.indexOf('NotAllowed') > -1 || error.name.indexOf('NotAllowed') > -1) {
          this.addNotification('You must allow camera and microphone to enter call.', 'negative');
          this.setState({ mediaBlocked: true });

          // check if twilio room timed out
        } else if (error.message.indexOf('Unable to create Room') > -1) {
          this.addNotification('Unable to create the room.', 'negative');
          this.setState({ roomLive: false, visitEnded: true });
        } else if (error.message.indexOf('NotReadableError') > -1 || error.message.indexOf('TrackStartError') > -1) {
          this.addNotification('An error occurred when trying to get access to your camera. Please close any other apps currently using the camera and reload this page.', 'negative');
        } else {
          this.addNotification('An error occurred when trying to join.', 'negative')
        }

        this.setState({
          autoJoin:         false,
          showJoinSpinner:  false,
          requestPending:   false,
          showVideoSpinner: false
        });
      });
    }
  }

  async getBackgroundImages() {
    let pathBackgroundImages = await apiRequest('get',`video-rooms/${this.state.videoRoomId}/backgrounds`);
    let pathImages  = U.get(pathBackgroundImages,'assetsPath'),
        imagesNames = U.get(pathBackgroundImages,'backgroundImages'),
        backgroundImagesMap = new Map();

    for(let i=0 ; i < imagesNames.length ; i++){
      new Promise((resolve, reject) => {
        let img = new Image();
        img.src = pathImages+imagesNames[i];
        img.onload = async () => {
          resolve(img);
          backgroundImagesMap.set(imagesNames[i], img);
        }
        img.onerror = reject;
      })
    }

    this.setState({backgroundImagesMap, pathImages})
  } 

  async onRoomConnected(room) {
    if (this.state.autoJoin)
      this.state.history.push(`?i=${this.props.inviteToken}`);

    if (this.state.isAdmin)
      apiRequest('post', `video-rooms/${this.state.videoRoomId}/room-live`, { sessionName: this.state.sessionName, roomLive: this.state.roomLive });

    apiRequest('post', `participants/${this.state.userId}/room-join`, { sessionName: this.state.sessionName });

    //load images for backgrounds
    await this.getBackgroundImages();

    this.setState({
      activeRoom:       room,
      nameError:        null,
      requestPending:   false,
      roomConnected:    true,
      showJoinSpinner:  false,
      showSplashScreen: false,
      canRejoinVisit: false
    });

    this.pusherStateChannels();
  }

  async onRoomDisconnected(roomLive = true, startedAt, endedAt) {
    await this.setState({
      activeRoom:     null,
      audioMuted:     false,
      autoJoin:       false,
      localTracks:    null,
      roomConnected:  false,
      roomLive:       roomLive,
      videoMuted:     false,
      startedAt,
      endedAt
    });

    if (this.appId)
      this.sendInterAppSignal('endVisit');

    pusherTools.subscribeToInitChannels(this.state.sessionName, this.state.userIdUnique);

    if (this.state.isAdmin)
      pusherTools.subscribeToAdminChannel(this.state.inviteToken);
  }


  async getDevices() {
    var { videoDevices, videoDeviceId } = this.state;
    var videoOptions = {
      width:  {
        min: 640,
        max: (isMobile) ? 640 : 1280,
        ideal: (isMobile) ? 640 : 1280
      },
      height: {
        min: 480,
        max: (isMobile) ? 480 : 720,
        ideal: (isMobile) ? 480 : 720
      },
      frameRate:  24
    };
    var audioOptions = true;

    var videoId = getSessionVideoID();
    var audioId = getSessionAudioID();

    var devices = await navigator.mediaDevices.enumerateDevices();

    var videoDevice = devices.find(device => device.deviceId === videoId);
    var audioDevice = devices.find(device => device.deviceId === audioId);

    if (videoDevice) {
      videoOptions.deviceId = videoDevice.deviceId;
    }  else if (videoDeviceId != null && videoDevices.length) {
      videoOptions.deviceId = videoDevices[videoDeviceId].id;
    } else {
      // when device is not available (disconnected or any other reason)
      deleteSessionVideoID(null);
    }

    if (audioDevice) {
      audioOptions = { deviceId: audioDevice.deviceId };
    } else {
      // when device is not available (disconnected or any other reason)
      deleteSessionAudioID(null);
    }

    await Twilio.createLocalTracks({
      audio: audioOptions,
      video: videoOptions
    })
    .then((localTracks) => {
      this.updateDeviceLists(devices);

      this.setState({
        localTracks,
        mediaBlocked:     false,
        showWaitingPage:  false,
        mediaMissing:     false
      });

      return localTracks;
    }, (error) => {
      console.log('Error when trying to create LocalTracks (Twilio)', error);

      var errorMessage = `${error.message}:::${error.name}`;
      this.setState({ showWaitingPage: false });

      // check permissions
      if (errorMessage.match(/(permission|NotAllowed|OverconstrainedError)/i)) {
        this.addNotification('You must first allow camera and microphone permissions.', 'negative');
        this.setState({ mediaBlocked: true });
      }

      if (errorMessage.match(/not found/i) && !this.state.mediaMissing)
        navigator.mediaDevices.enumerateDevices().then(this.updateDeviceLists);

      if (errorMessage.match(/(NotReadableError|TrackStartError)/i))
        this.addNotification('An error occurred when trying to get access to your camera. Please close any other apps currently using the camera and reload this page.', 'negative')
    });
  }

  async _updateDeviceLists() {
    if (this.state.mediaMissing)
      await this.getDevices();

    var devices = await navigator.mediaDevices.enumerateDevices();
    await this.updateDeviceLists(devices);
  }

  async updateDeviceLists(devices) {
    if (devices.length < 1)
      this.addNotification('No input devices detected.', 'negative');

    var videoDevices  = [],
        audioDevices  = [],
        videoDeviceId = this.state.videoDeviceId,
        audioDeviceId = this.state.audioDeviceId,
        mediaMissing;

    devices.forEach((device) => {
      if (device.deviceId === '')
        return; // if user don't give permission yet is blank

      var deviceInfo = {
        label:  device.label,
        id:     device.deviceId
      };

      // If permissions haven't been given for media access, all device ids will be blank
      if (!deviceInfo.id)
        return;

      if (device.kind === 'videoinput') {
        videoDevices.push(deviceInfo);
      } else if (device.kind === 'audioinput') {
        audioDevices.push(deviceInfo);
      }
    });

    mediaMissing = !(videoDevices.length && audioDevices.length);

    this.setState({ videoDevices, audioDevices, mediaMissing, hasDeviceList: !mediaMissing });

    const getDeviceIndex = (id, list) => {
      var index = list.findIndex((device) => (device.id === id));
      return (index < 0) ? null : index;
    };

    var videoDeviceFromUrl = this.getUrlParams.get('vd'),
        audioDeviceFromUrl = this.getUrlParams.get('ad'),
        currentVideoDevice = (videoDeviceId != null) ? this.state.videoDevices[videoDeviceId] : getDeviceIndex(videoDeviceFromUrl, videoDevices),
        currentAudioDevice = (audioDeviceId != null) ? this.state.audioDevices[audioDeviceId] : getDeviceIndex(audioDeviceFromUrl, audioDevices);

    const getUpdatedDeviceIndex = (currentDevice, deviceArray) => {
      if (!currentDevice || deviceArray.length === 0)
        return null;

      var newIndex = findIndex(deviceArray, (device) => (device.id === currentDevice.id));

      if (newIndex < 0) {
        newIndex = 0;
        this.addNotification(`Device Disconnected: ${currentDevice.label}`, 'neutral');
      }

      return newIndex;
    };

    var newVideoDeviceId = getUpdatedDeviceIndex(currentVideoDevice, videoDevices),
        newAudioDeviceId = getUpdatedDeviceIndex(currentAudioDevice, audioDevices);

    if (newVideoDeviceId !== videoDeviceId || videoDeviceId === null)
      this.updateVideoDeviceId(newVideoDeviceId);

    if (newAudioDeviceId !== audioDeviceId || audioDeviceId === null)
      this.updateAudioDeviceId(newAudioDeviceId);
  }

  // // change camera
  async updateVideoDeviceId(_deviceId) {
    var deviceId      = (Number.isInteger(_deviceId)) ? _deviceId : (this.state.videoDeviceId || 0),
        videoDevices  = this.state.videoDevices || [],
        videoDeviceId = (deviceId <= videoDevices.length - 1) ? deviceId : null;

    var videoId = getSessionVideoID();
    if (videoId)
      videoDeviceId = videoDevices.findIndex(device => device.id === videoId);

    this.setState({ videoDeviceId });
  }

  async updateAudioDeviceId(_deviceId) {
    var deviceId      = (Number.isInteger(_deviceId)) ? _deviceId : (this.state.audioDeviceId || 0),
        audioDevices  = this.state.audioDevices || [],
        audioDeviceId = (deviceId <= audioDevices.length - 1) ? deviceId : null;

    var audioId = getSessionAudioID();
    if (audioId) 
      audioDeviceId = audioDevices.findIndex(device => device.id === audioId);

    this.setState({ audioDeviceId });
  }

  roomRestricted(reason) {
    this.setState({ requestPending: false, showJoinSpinner: false });

    switch (reason) {
      case 'host-denied':
        this.addNotification('The host has denied access.', 'negative');
        this.setState({ isDenied: true, isAdmin: false });
        break;
      case 'room-full':
        if (this.state.isAdmin) {
          this.addNotification('Sorry, this room is full', 'negative');
        } else {
          this.addNotification("Sorry, this room is full, but we're notifying the host.", 'negative');
          setTimeout(() => this.addNotification('Try again in a few minutes.', 'neutral'), 2000);
        }
        break;
      case 'no-host':
        this.addNotification("Sorry, there are no hosts to allow you into the room.", 'negative');
        setTimeout(() => this.addNotification('Try again in a few minutes.', 'neutral'), 2000);
        break;
      case 'id-in-use':
        this.addNotification("Sorry, we experienced an error. Please refresh and request again.", 'negative');
        break;
      case 'name-in-use':
        this.setState({
          nameError:        `A ${this.state.displayNameInput} is already in the call.`,
          showJoinSpinner:  false
        });
        break;
      default:
        this.addNotification("Sorry, the room is currently restricted.", 'negative');
    }
  }

  // main method for handling entering room flow, fires from button click
  async enterRequest() {
    var {
          displayName,
          displayNameInput,
          entryApproved,
          inviteToken,
          isAdmin,
          knockFirst,
          roomInvite,
          roomSwitch,
          userId,
          userIdUnique,
          videoRoomId
        }           = this.state,
        response;

    this.setState({ hostEnded: false, showJoinSpinner: true });

    // if host switched rooms, came through pusher earlier, will now go get a new token.
    if (roomSwitch)
      return this.switchRooms();

    if (knockFirst) {
      var requestData = {
        inviteToken:  inviteToken,
        videoRoomId:  videoRoomId,
        name:         displayNameInput
      };

      if (displayNameInput !== displayName)
        requestData.nameChange = true;

      response = await apiRequest('post', `participants/${userId}/room-return`, requestData);

      if (response && response.restrictedReason)
        return this.roomRestricted(response.restrictedReason);
    }

    if ((!entryApproved || roomInvite) && !isAdmin) {
      this.setState({ requestPending: true });

      response = await apiRequest('post', `video-rooms/${videoRoomId}/room-request`, {
        userId: userId,
        id:     userIdUnique,
        name:   displayNameInput
      });

      if (response && response.restrictedReason)
        return this.roomRestricted(response.restrictedReason);
    }

    this.joinRoom();
  }

  // transact twilio access token for participant
  transactRoomToken(participantId) {
    let requestData = {
      videoRoomId:  this.state.videoRoomId,
      userName:     this.state.displayNameInput
    };

    Axios.post(`${process.env.REACT_APP_URL_API}/api/participants/${participantId}/transact-token`, requestData, {
      withCredentials: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      let data = response.data;

      if (data.isNewUser) {
        this.state.history.push(`?i=${data.inviteToken}`);
        this.setState({ inviteToken: data.inviteToken, userId: data.id });        
      }

      this.authorize(data.tempWebToken);
      this.setState({
        roomInvite:     false,
        twilioToken:    data.twilioAccessToken,
        entryApproved:  true,
        hostEnded:      (data.videoRoom.endedAt) ? true : false,
        displayName:    data.name,
        inviteExpired:  false,
        isDenied:       false,
        knockFirst:     true
      });

      this.enterRequest();
    })
      .catch((error) => {
        return null;
      });
  }

  // methods are attached to approval requests to host/admin.
  async allowAccess(item, isApproved) {
    let request = this.state.notifications.find((notification) => (notification.id === item.id));

    request.approved = isApproved;
    this.removeNotification(item);

    const response = await apiRequest('post', `video-rooms/${this.state.videoRoomId}/room-approval`, request);

    if (!isApproved && item.userId) {
      this.denyAccess({id: item.userId})
    }
  }

  // deny user in room
  denyAccess(user) {
    let request = {
      videoRoomId:  this.state.videoRoomId,
      sessionName:  this.state.sessionName,
    };

    apiRequest('post', `participants/${user.id}/deny`, request)
  }

  // make host user in room
  updateHost(user, isAdmin) {
    let request = {
      sessionName:  this.state.sessionName,
      isAdmin
    };

    apiRequest('post', `/participants/${user.id}/make-host`, request);
  }
  
  renderNotifications() {
    var notifications = this.state.notifications;

    return (
      <div style={{ zIndex: 999 }} className="alerts-container">
        {(notifications.length > 0) && notifications.map((notification, key) => {
          return (
            <div
              key={key}
              className={classNames((notification.toast) ? ('alert ' + notification.toast) : ('alert positive'))}
            >
              <div className="alert-content">
                {(notification.request) ? notification.request : notification.name}
              </div>

              {!!notification.toast &&
                <div className="close-alert icon-btn" onClick={(key) => this.removeNotification(notification)}>
                  <i className="icon-close"></i>
                </div>
              }

              {!notification.toast &&
                <div className="alert-actions">
                  <audio id="join-request-audio" src={JoinRequestAlertSound} type="audio/mp3"/>
                  <button data-test-id="deny-access" className="transparent small" onClick={(key) => this.allowAccess(notification, false)}>
                    Deny
                  </button>
                  <button data-test-id="allow-access" className="outline-white small" onClick={(key) => this.allowAccess(notification, true)}>
                    Allow
                  </button>
                </div>
              }
            </div>
          )})
        }
      </div>
    );
  }

  renderWaitingPage() {
    var { showSplashScreen, showWaitingPage } = this.state;

    if (showSplashScreen)
      setTimeout(() => this.setState({ showSplashScreen: false }), SPLASH_DURATION);

    return (
      <main className="main-wrapper">
        <img className="evisit-logo" src={ExpressLogo} alt="eVisit Express Logo"/>

        {!!showSplashScreen && <img className="splash-logo" src={SplashLogo} alt="Splash Screen Logo"/>}
        {(!showSplashScreen && showWaitingPage) && this.renderVideoSpinner()}
      </main>
    );
  };

  renderVideoSpinner() {
    return (
      <div className="loader-spinner">
        <img className="spinner" src={Spinner} alt="loader"/>
      </div>
    )
  };

  renderVideoChatPage() {
    const props = {
      activeRoom: this.state.activeRoom,
      appId: this.state.appId,
      autoJoin: this.state.autoJoin,
      audioMuted: this.state.audioMuted,
      audioDeviceId: this.state.audioDeviceId,
      audioDevices: this.state.audioDevices,
      displayName: this.state.displayName,
      inviteToken: this.state.inviteToken,
      isAdmin: this.state.isAdmin,
      isDenied: this.state.isDenied,
      roomInviteUrl: this.state.roomInviteUrl,
      roomLive: this.state.roomLive,
      sessionName: this.state.sessionName,
      userId: this.state.userId,
      userIdUnique: this.state.userIdUnique,
      videoDeviceId: this.state.videoDeviceId,
      videoDevices: this.state.videoDevices,
      videoMuted: this.state.videoMuted,
      videoRoomId: this.state.videoRoomId,
      backgroundImagesMap: this.state.backgroundImagesMap,
      pathImages: this.state.pathImages,
      practiceId: this.state.practiceId,
      addNotification: this.addNotification,
      denyAccess: this.denyAccess,
      updateHost: this.updateHost,
      onRoomDisconnected: this.onRoomDisconnected,
      updateSessionState: (updates) => this.setState(updates),
      trackEvent,

      worker: instance
    }

    return (
      <VideoChatPage {...props} />
    );
  }

  renderWaitingRoomPage() {
    return (
      <WaitingRoomPage
        audioDevices={this.state.audioDevices}
        displayName={this.state.displayName}
        durationTimes={this.state.durationTimes}
        endedAt={this.state.endedAt}
        entryApproved={this.state.entryApproved}
        hostEnded={this.state.hostEnded}
        initPing={this.state.initPing}
        inviteToken={this.state.inviteToken}
        isAdmin={this.state.isAdmin}
        isDenied={this.state.isDenied}
        canRejoinVisit={this.state.canRejoinVisit}
        onRejoinVisit={this.rejoinVisit}
        mediaBlocked={this.state.mediaBlocked}
        mediaMissing={this.state.mediaMissing}
        nameError={this.state.nameError}
        requestPending={this.state.requestPending}
        roomLive={this.state.roomLive}
        showJoinSpinner={this.state.showJoinSpinner}
        startedAt={this.state.startedAt}
        videoDevices={this.state.videoDevices}
        visitEnded={this.state.visitEnded}
        tokenInUse={this.state.tokenInUse}
        addNotification={this.addNotification}
        enterRequest={this.enterRequest}
        updateDisplayNameInput={(displayNameInput) => this.setState({ displayNameInput })}
      />
    );
  }

  render() {
    if (this.state.browserApproved === false)
      return window.location.replace(
        `https://${process.env.REACT_APP_EVISIT_ENV}.evisit.com/unsupported_browser?express=true`
      );

    if (!this.state.inviteToken)
      window.location.href = EVISIT_LOGIN_URL;

    if (this.state.showSplashScreen || this.state.showWaitingPage)
      return this.renderWaitingPage();

    return (
      <main className="main-wrapper">
        <img className="evisit-logo" src={ExpressLogo} alt="eVisit Express Logo"/>

        {(this.state.showVideoSpinner) && this.renderVideoSpinner()}

        {this.renderNotifications()}

        {(this.state.roomConnected && (!this.state.inviteExpired && !this.state.isDenied && !this.state.canRejoinVisit))
          ? (this.renderVideoChatPage())
          : (this.renderWaitingRoomPage())
        }
      </main>
    )
  }
}

export default App;
