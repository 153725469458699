import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import U                    from '../utils/tools';

class ParticipantStatusBar extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    U.bindFunctions(this);
  }

  static propTypes = {
    audioMuted:         PropTypes.bool,
    currentUserIsAdmin: PropTypes.bool,
    mode:               PropTypes.string,
    onMuteUser:         PropTypes.func,
    userName:           PropTypes.string,
    isPresentation:     PropTypes.bool
  };

  static defaultProps = {
    audioMuted:     true,
    isPresentation: false,
    mode:           'remote',
    videoMuted:     true
  };

  _onMuteUser() {
    U.callPropFunction(this.props.onMuteUser)
  }

  renderMediaStatus() {
    var { currentUserIsAdmin, audioMuted, videoMuted } = this.props,
        micLabel = (audioMuted) ? 'This person must unmute themselves' : 'Mute',
        micClass = (audioMuted) ? 'muted' : '';

    return (
      <div className="status-bar">
        <i className={(videoMuted) ? 'icon-video-off' : 'icon-video'}/>
        <i
          className={(audioMuted) ? 'icon-mic-off' : 'icon-mic'}
          onClick={(currentUserIsAdmin) ? this._onMuteUser : null}
        />

        {!!currentUserIsAdmin &&
          <span className="status-mic">
            <label className={micClass}>{micLabel}</label>
          </span>
        }
      </div>
    );
  }

  renderRemoteStatusBar() {
    var { userName, isPresentation } = this.props;

    return (
      <React.Fragment>
        {this.renderMediaStatus()}

        {!!userName && <span className="participant-name">{userName}</span>}
        {!!isPresentation && <div className="footer-bar">Presenting</div>}
      </React.Fragment>
    );
  }

  renderLocalStatusBar() {
    var { audioMuted, videoMuted } = this.props;

    return (
      <div className="status-bar local">
        <i id="local-video" className={(videoMuted) ? 'icon-video-off' : 'icon-video'}/>
        <i id="local-mic" className={(audioMuted) ? 'icon-mic-off' : 'icon-mic'}/>
      </div>
    );
  }

  render() {
    var mode    = this.props.mode,
        isLocal = (mode === 'local');

    return (isLocal) ? this.renderLocalStatusBar() : this.renderRemoteStatusBar();
  }
}

export default ParticipantStatusBar;
