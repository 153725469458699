import React, { Component }                   from 'react';
import PropTypes                              from 'prop-types';
import { isEdge, isFirefox, isIOS, isMobile } from 'react-device-detect';
import mediaPermissionsExample                from '../../images/media-permissions-example.gif';
import U                                      from '../../utils/tools';

class MediaPermissionsModal extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      guideMode: false
    };

    U.bindFunctions(this);
  }

  static propTypes = {
    onOpenHelpModal: PropTypes.func
  };

  _onOpenHelpModal() {
    U.callPropFunction(this.props.onOpenHelpModal);
  }

  getHelpVideoUrl() {
    // Generic Browser
    if (!isMobile)
      return 'https://player.vimeo.com/video/463240519';

    if (isIOS)
      return 'https://player.vimeo.com/video/463480175';

    if (isFirefox)
      return 'https://player.vimeo.com/video/463599223';

    if (isEdge)
      return 'https://player.vimeo.com/video/463599145';

    // Generic Mobile
    return 'https://player.vimeo.com/video/463480048';
  }

  // TODO: This might need some sort of back step to get back to non-guideMode but currently it didn't exist
  renderPermissionsGuideModal() {
    var helpVideoUrl = this.getHelpVideoUrl();

    return (
      <div className="modal mobile-modal media-permissions-guide-modal">
        <div className="modal-content">
          <div className="modal-body center">
            <h1 className="title center">Follow this video guide</h1>
            <h6>and allow access to your camera and microphone.</h6>

            <iframe
              title="guide"
              src={helpVideoUrl}
              frameBorder="0"
              allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture"
              allowFullScreen
            />

            <button data-test-id="cant-see-video" className="link" onClick={this._onOpenHelpModal}>
              Can't see the video?
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionsModal() {
    return (
      <div className="modal mobile-modal media-permissions-modal">
        <div className="modal-content small">
          <div className="modal-body center">
            <h1 className="title center">Allow access</h1>
            <h6>Please share your camera and microphone.</h6>

            <img className="example" src={mediaPermissionsExample} alt={'example'}/>

            <button data-test-id="dont-see-prompt" className="link" onClick={() => this.setState({ guideMode: true })}>
              Don't see the prompt?
            </button>

            <button data-test-id="refresh-now" className="join-room large action" onClick={() => window.location.reload()}>
              Refresh Now
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    var guideMode = this.state.guideMode;

    return (
      (guideMode) ? this.renderPermissionsGuideModal() : this.renderPermissionsModal()
    );
  }
}

export default MediaPermissionsModal;
