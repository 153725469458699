import React, { Component }  from 'react';
import PropTypes             from 'prop-types';
import classNames            from 'classnames';
import InformationModal      from './modals/information-modal';
import HelpModal             from './modals/help-modal';
import MediaMissingModal     from './modals/media-missing-modal';
import MediaPermissionsModal from './modals/media-permissions-modal';
import VisitEndedModal       from './modals/visit-ended-modal';
import Spinner               from '../images/loader-tail-spin.svg';
import ModalSpinner          from '../images/loader-tail-spin-grey.svg';
import U                     from '../utils/tools';

class WaitingRoomPage extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      helpModalVisible: false,
      nameError:        this.props.nameError || null,
      displayNameInput: this.props.displayName || '',
      nameProvided:     !!this.props.displayName
    };

    U.bindFunctions(this);
  }

  static propTypes = {
    audioDevices:           PropTypes.array,
    displayName:            PropTypes.string,
    durationTimes:          PropTypes.object,
    endedAt:                PropTypes.string,
    entryApproved:          PropTypes.bool,
    hostEnded:              PropTypes.bool,
    initPing:               PropTypes.bool,
    inviteToken:            PropTypes.string,
    isAdmin:                PropTypes.bool,
    isDenied:               PropTypes.bool,
    canRejoinVisit:         PropTypes.bool,
    mediaBlocked:           PropTypes.bool,
    mediaMissing:           PropTypes.bool,
    nameError:              PropTypes.string,
    requestPending:         PropTypes.bool,
    roomLive:               PropTypes.bool,
    showJoinSpinner:        PropTypes.bool,
    startedAt:              PropTypes.string,
    tokenInUse:             PropTypes.bool,
    videoDevices:           PropTypes.array,
    visitEnded:             PropTypes.bool,
    addNotification:        PropTypes.func,
    enterRequest:           PropTypes.func,
    onRejoinVisit:          PropTypes.func,
    updateDisplayNameInput: PropTypes.func
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.nameError !== this.props.nameError)
      this.setState({ nameError: this.props.nameError });
  }

  _handleKeyDownName(event) {
    if (event.key !== 'Enter')
      return;

    this._onEnter();
  };

  renderModalSpinner() {
    return (
      <div className="modal-spinner">
        <img className="spinner" src={ModalSpinner} alt="loader"/>
      </div>
    );
  }

  async _onEnter() {
    if (this.state.displayNameInput.length < 1)
      return this.setState({ nameError: 'Name is required to enter.', showJoinSpinner: false });

    this.setState({ showJoinSpinner: true });
    await U.callPropFunction(this.props.updateDisplayNameInput, [this.state.displayNameInput]);

    if (this.props.canRejoinVisit)
      U.callPropFunction(this.props.onRejoinVisit)
    else
      U.callPropFunction(this.props.enterRequest);
  }

  getVisitEndedModal() {
    if (this.props.startedAt == null || this.props.endedAt == null)
      return <InformationModal header="Visit Ended" body="This room is no longer available" />;

    var startDateTime   = new Date(this.props.startedAt),
        endDateTime     = new Date(this.props.endedAt),
        dateFormatted   = startDateTime.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }),
        durationSeconds = Math.round((endDateTime.getTime() - startDateTime.getTime()) / 1000),
        durationTimes   = this.props.durationTimes;

    return <VisitEndedModal
      header="Visit Ended"
      date={dateFormatted}
      durationSeconds={durationSeconds}
      durationTimes={durationTimes}
      inviteToken={this.props.inviteToken}
      footer="This room is no longer available"
      renderModalSpinner={this.renderModalSpinner}
    />;
  };

  getInformationModal() {
    if (!this.props.isAdmin && !this.props.initPing)
      return null;

    if (this.props.isAdmin)
      return <InformationModal header="Visit Ended" body="This room is no longer available" />;

    if (this.props.hostEnded || this.props.visitEnded)
      return <InformationModal header="Your visit has ended" />;

    if (!this.props.roomLive) {
      return <InformationModal header="Just a Minute" body="Waiting for host" />;
    } else if (this.props.requestPending) {
      return <InformationModal header="Just a Minute" body="Waiting for access" />;
    }
  };

  getMediaHelpModal() {
    if (this.state.helpModalVisible)
      return <HelpModal />;

    if (this.props.mediaBlocked)
      return <MediaPermissionsModal onOpenHelpModal={() => this.setState({ helpModalVisible: true })}/>;

    if (this.props.mediaMissing) {
      var { videoDevices, audioDevices }  = this.props,
          cameraMissing                   = !(videoDevices || []).length,
          microphoneMissing               = !(audioDevices || []).length;

      return (
        <MediaMissingModal
          cameraMissing={cameraMissing}
          microphoneMissing={microphoneMissing}
          onOpenHelpModal={() => this.setState({ helpModalVisible: true })}
        />
      );
    }
  }

  render() {
    if (this.props.mediaBlocked || this.props.mediaMissing || this.state.helpModalVisible)
      return this.getMediaHelpModal();

    if (this.props.isDenied)
      return <InformationModal body="The host has denied access to this visit." />

    if (this.props.tokenInUse) {
      return (
        <InformationModal
          body="You are already in the visit. If you cannot find the visit, close your browser and select the link to rejoin."
        />
      );
    }

      var {
          displayNameInput,
          displayName,
          nameError,
          nameProvided
        }                     = this.state,
        {
          isAdmin,
          roomLive,
          entryApproved,
          requestPending,
          showJoinSpinner,
          visitEnded,
          canRejoinVisit
        }                     = this.props,
        canJoinRoom           = (roomLive || isAdmin),
        roomUnavailable       = (!canJoinRoom || visitEnded),
        buttonText,
        titleText;

    if (visitEnded && isAdmin)
      return this.getVisitEndedModal();

    if (requestPending || roomUnavailable)
      return this.getInformationModal();

    if (isAdmin && !roomLive) {
      buttonText = 'Get Started';
    } else if (canRejoinVisit) {
      buttonText = 'Rejoin Now';
    } else if (entryApproved) {
      buttonText = 'Enter Room';
    } else {
      buttonText = 'Join Now';
    }

    if (canRejoinVisit) {
      titleText = 'Rejoin the Visit';
    } else if (nameProvided) {
      titleText = 'Please verify your name';
    } else {
      titleText = 'Please enter your name';
    }

    return (
      <div className='join-wrapper'>
        <h1>
          {titleText}
        </h1>

        <div className="input-group">
          <input
            className={classNames((displayNameInput) ? 'entered' : null)}
            type="text"
            name="displayName"
            disabled={canRejoinVisit && Boolean(displayName)}
            value={displayNameInput}
            onKeyDown={this._handleKeyDownName}
            onChange={(event) => this.setState({ displayNameInput: event.target.value, nameError: null })}
          />
          <label>Your Name</label>
        </div>

        {nameError && <p className="error">{nameError}</p>}
        <button
          data-test-id="enter-express-visit"
          className="join-room large action"
          disabled={showJoinSpinner}
          style={{ pointerEvents: 'auto' }}
          onClick={() => this._onEnter()}
        >
          {(showJoinSpinner)
            ? <img className="spinner" src={Spinner} alt="loader"/>
            : buttonText
          }
        </button>
      </div>
    );
  }
}

export default WaitingRoomPage;
