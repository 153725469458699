import React from 'react';
import { BACKGROUND_IMAGE_NONE } from '../../utils/background-tools';
import { BACKGROUND_TYPE_BLUR } from '../../utils/background-tools';
import BackgroundBlurImage from '../../images/background-blur.jpg';

const ImageSelector = (props) => {
  const { imagesNames, imagesPath, imageSelected, caption }  = props;

  const handleImageSelect = (imageName) => {
    props.onImageSelected(imageName);
  }

  const createCaptionText = (caption) => {
    caption = caption.toLowerCase();
    caption = caption.charAt(0).toUpperCase() + caption.slice(1);
    return caption;
  }

  const renderImages = () => {
    return imagesNames.map((imageName) => {
      return (
        <div className="image-content" key={imageName}>
        <img
        className={(imageSelected === imageName) ? "image-option-selected" : "image-option"}
        name={imageName}
        onClick={() => handleImageSelect(imageName)}
        src={imageName === BACKGROUND_TYPE_BLUR ? BackgroundBlurImage : imagesPath+imageName}
        alt={imageName}
        />
        { caption && (
          <label
          key={`${imageName}-caption`}
          className="image-label"
          onClick={() => handleImageSelect(imageName)}>
          {createCaptionText(imageName)}
          </label>
        )}
        </div>
      )
    });
  }

  return (
    <div className="image-selector">
      <div
      key={BACKGROUND_IMAGE_NONE}
      className={(imageSelected === BACKGROUND_IMAGE_NONE) ? 
                  "image-content image-none image-option-selected" : 
                  "image-content image-none image-option"}
      onClick={() => handleImageSelect(BACKGROUND_IMAGE_NONE)}>
        <label>
          None
        </label>
      </div>
      {renderImages()}
    </div>
  );
};


export default ImageSelector;