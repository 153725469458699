// Background Types
export const BACKGROUND_TYPE_BLUR  = 'blur';
export const BACKGROUND_TYPE_NONE  = 'none';
export const BACKGROUND_TYPE_IMAGE = 'image';

// Background image types
export const BACKGROUND_IMAGE_NONE = 'none';

// Path to video processors assets from twilio
export const VIRTUAL_BACKGROUND_ASSETS = '/virtualbackground';

export const BACKGROUND_BLUR_SETTINGS = {
    assetsPath: VIRTUAL_BACKGROUND_ASSETS,
    maskBlurRadius: 5,
    blurFilterRadius: 15,
};

// Background Notifications
export const BACKGROUND_BLUR_NOT_SUPPORTED   = 'Background blur is only supported on Google Chrome and Microsoft Edge.';
export const BACKGROUND_IMAGES_NOT_SUPPORTED = 'Background image is only supported on Google Chrome and Microsoft Edge.';