import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import generateClassNames   from 'classnames';
import { isMobile }         from 'react-device-detect';
import ParticipantStatusBar from './participant-status-bar';
import VideoUtils           from '../utils/video-tools';
import U                    from '../utils/tools';

class LocalMedia extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.localMediaReference = React.createRef();
    U.bindFunctions(this);
  }

  static propTypes = {
    audioMuted:               PropTypes.bool,
    attachAudioLevelListener: PropTypes.func,
    missingVideoDevice:       PropTypes.bool,
    participant:              PropTypes.object,
    sharingScreen:            PropTypes.bool,
    videoMuted:               PropTypes.bool,
    facingMode:               PropTypes.string
  };

  static defaultProps = {
    audioMuted:     true,
    sharingScreen:  false,
    videoMuted:     true
  };

  componentDidMount() {
    this.attachParticipantListeners()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.participant !== this.props.participant)
      this.attachParticipantListeners();
  }

  async attachParticipantListeners() {
    var participant = this.props.participant;

    if (!participant)
      return;

    participant.on('trackPublished', (publication) => this.trackPublished(publication));
    participant.on('trackUnpublished', (publication) => {
      VideoUtils.detachTrack(U.get(publication, 'track'))
    });

    participant.tracks.forEach((publication) => this.trackPublished(publication));
  }

  async trackPublished(publication) {
    var container = U.get(this.localMediaReference, 'current'),
        track     = U.get(publication, 'track'),
        trackName = U.get(publication, 'trackName'),
        trackKind = U.get(track, 'kind');

    if (trackName === 'screenshare')
      return;

    await VideoUtils.attachTrack(track, container);

    if (!isMobile && (trackKind === 'audio')) {
      var audioElements = container.getElementsByTagName('audio'),
          audioElement  = (audioElements || [])[0];

      U.callPropFunction(this.props.attachAudioLevelListener, [ audioElement ]);
    }
  }

  renderNoVideoMessage() {
    var missingVideoDevice = this.props.missingVideoDevice;

    if (missingVideoDevice) {
      return (
        <div className="video-hidden">
          No Video detected.
        </div>
      );
    }

    return (
      <div className="video-hidden">
        Your camera is off.
      </div>
    );
  }

  renderPresentingInformation() {
    return (
      <div className="presenting">
        <span>Sharing Screen</span>
        <button
          data-test-id="stop-screen-sharing"
          className="small warn"
          onClick={() => U.callPropFunction(this.props.toggleScreenShare)}
        >
          Stop
        </button>
      </div>
    );
  }

  render() {
    var {
          audioMuted,
          sharingScreen,
          missingVideoDevice,
          videoMuted,
          facingMode
        }               = this.props,
        pipVideoMuted   = (videoMuted && !sharingScreen),
        noVideoPresent  = (pipVideoMuted || missingVideoDevice);

    return (
      <div
        ref={this.localMediaReference}
        className={generateClassNames(
          'local-media',
          'mirror',
          facingMode,
          (sharingScreen) ? 'presentation' : null
        )}
        id="local-media"
      >
        {!!sharingScreen && this.renderPresentingInformation()}
        {!!noVideoPresent && this.renderNoVideoMessage()}

        <ParticipantStatusBar
          audioMuted={audioMuted}
          mode={'local'}
          videoMuted={pipVideoMuted}
        />
      </div>
    )
  }
}

export default LocalMedia;
